import React, { useEffect, useRef, useState } from "react";
import ContractViewer from "../components/ContractViewer";
import "../style/ContractComplete.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useReactToPrint } from 'react-to-print';

function ContractComplete({ lang }) {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const { contract_id } = useParams();
  const [contractData, setContractData] = useState();
  const [officeName, setOfficeName] = useState('');
  const currDate = new Date();
  function convertDateFormat(inputDate, local) {
    const dateObject = new Date(inputDate);
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };

    const formattedDate = dateObject.toLocaleDateString(local, options);
    return formattedDate;
  }

  const contractComplete = contractData && `
    <div id="container" style="width: 800px;" dir="rtl">
        <form>
          <fieldset>
            <legend style="font-size: 25px;">
             ${officeName}
            </legend>
            <br />

            <div id="part-1">
              <h2 class="cc-heading">مخالصة مالية</h2>
              <div style="display: flex; gap: 100px; justify-content: space-evenly;">
                <h3></h3>
                <h3></h3>
                <h3>تاريخ : ${convertDateFormat(currDate, "ar-SA")}</h3>
                <h3>الموافق : ${convertDateFormat(currDate)}</h3>
              </div>
              <div class="cc-main">
                <label class='cc-label' for="first-name"> تم تسديد العقد رقم: </label>
                <div class=" cc-input">
                  <div style="border-bottom: 2px solid black; margin-top: 1rem;">${contractData.contract_id * 9999}</div>
                </div>

                <label class='cc-label' for="first-name">من السيد:</label>
                <div class="cc-input">
                  <div style="border-bottom: 2px solid black; margin-top: 1rem;">${contractData.user_name}</div>
                </div>
              </div>
              <br />
              <div class="cc-main">
                <label class='cc-label' for="first-name">بمبلغ وقدره: </label>
                <div class=" cc-input">
                  <div style="border-bottom: 2px solid black; margin-top: 1rem;">${contractData.total_value}</div>
                </div>

                <label class='cc-label' for="first-name">وذلك قيمة :</label>
                <div class="cc-input">
                  <div style="border-bottom: 2px solid black; margin-top: 1rem; ">${contractData.item_name}</div>
                </div>
              </div>
              <br />

              <div class=" cc-main">
                <label class='cc-label' for="first-name">بكفالة السيد : </label>
                <div class=" cc-input">
                  <div style="border-bottom: 2px solid black; margin-top: 1rem; ">${contractData.guarantor_name || ""}</div>
                </div>

                <label class='cc-label' for="first-name">لحساب السيد :</label>
                <div class="cc-input">
                  <div style="border-bottom: 2px solid black; margin-top: 1rem; ">${contractData.branch_name}</div>
                </div>
              </div>
              <br />
            </div>
            <br />
            <h4>وذلك على النحو التالي :-	</h4>
            <div id="part-2">
              <div class="part-2">
                <li>قيمة العقــد: ${contractData.total_value}</li>
                <li>دفعة مقدمة: ${contractData.down_payment}</li>
                <li>اجمالي الدفعات: ${contractData.no_of_installments}</li>
                <li>المتبقـــي: ${contractData.total_value - contractData.total_paid}</li>
              </div>
            </div>
            <br />
            <div class="cc-main">
              <label class='cc-label' for="first-name" style="width: 100%;">
              وبهذا يكون قد اخلى السيد/					
              </label>
              <div class=" cc-input">
                <div style="border-bottom: 2px solid black; margin-top: 1rem; ">${contractData.user_name}</div>
              </div>

              <div class="cc-input">
                <p style="font-size: 20px;">
                  طرفه من قبل المكتب وتعتبر هذه مخالصة نهائية
                </p>
              </div>
            </div>
            <br />
            <div class="cc-main">
              <div class=" cc-input">
                <p style="font-size: 20px;">لايحق للمكتب المطالبة بعدها</p>
              </div>

              <div class="cc-input" style="text-align: end;">
                <p style="font-size: 20px; position: relative;">توقيع المكتب</p>
                <div style="border-bottom: 2px solid black; margin-top: 1rem; width: 15%;left:4rem;margin-top: 2rem;position: absolute;"></div>
              </div>
            </div>

            <div style="display: flex;  justify-content: center; margin-top: 2rem;">
              <h1>الختم</h1>
            </div>
          </fieldset>
        </form>
      </div>`;

      useEffect(() => {
        try{
            axios.get("https://api.amancredits.com/get-complete-contract/" + contract_id).then(res => {
                setContractData(res.data.result[0])
            }).catch(err => {
                console.error(err)
            })
        } catch(err){
            console.error(err)
        }
        const storedUser = JSON.parse(JSON.parse(sessionStorage.getItem("aman-admin")).admin)
        if (storedUser) {
          setOfficeName(storedUser.branch_name)
        }
      }, [contract_id])
  return (
    <div>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>{lang.Customer_Management || "Customer Management"}</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <svg
                  className="sidebaricon"
                  xmlns="http://www.w3.org/2000/svg"
                  height="13"
                  width="15"
                  viewBox="0 0 576 512"
                >
                  <path d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z" />
                </svg>
              </li>
              <li className="breadcrumb-item">{lang.General_Management || "General Management"}</li>
              <li className="breadcrumb-item active">{lang.Valid_Contract || "Valid Contract"}</li>
              <li className="breadcrumb-item active">{lang.complete_contract || "Complete Contract"}</li>
            </ol>
          </nav>
        </div>

        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="row">
                  <div className="col-9"></div>
                  <div className="col-3">
                    <div></div>
                    <div to="/add-customer">
                      <button
                        type="button"
                        onClick={handlePrint}
                        className="btn btn-primary"
                      >
                        {lang.save_contract || "Save Contract"}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="row pt-3">
                  <div className="rr col-9">{lang.contract || "Contract"}</div>
                </div>
                <div className="card-body">
                  <div classNames="d-flex mt-3 mb-3">
                    <div className="w-100" id="contract-form" style={{paddingBottom: "16rem", overflowX: "auto"}} ref={componentRef}>
                      <ContractViewer htmlContent={contractComplete} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default ContractComplete;
