import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

export default function InvesWithdrawl({ lang }) {
  const [investorList, setInvestorList] = useState([]);
  const [investor, setInvestor] = useState("");
  const [account, setAccount] = useState("");
  const [ammount, setAmmount] = useState("");
  const [balanceBefore, setBalanceBefore] = useState("");
  const [balanceAfter, setBalanceAfter] = useState("");
  const [statement, setStatement] = useState("");
  const [accountList, setAccountList] = useState([]);
  const [branchId, setBranchId] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      axios
        .put("https://api.amancredits.com/investor-amount-withdraw/" + investor, {
          bank_account: account,
          amount: ammount,
          statement: statement,
        })
        .then((res) => {
          console.log(res.data);
          setInvestor("");
          setAccount("");
          setAmmount("");
          setBalanceAfter("");
          setBalanceBefore("");
          setStatement("");
          toast.success(res.data.Message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        })
        .catch((err) => {
          console.error(err);
          toast.error("Some error occured", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    } catch (err) {
      console.error(err);
      toast.error("Some error occured", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  useEffect(() => {
    if (branchId) {
      try {
        axios
          .get("https://api.amancredits.com/investor", {
            params: {
              branch_id: branchId,
            },
          })
          .then((res) => {
            setInvestorList(res.data.result);
          })
          .catch((err) => {
            console.error(err);
          });
        axios
          .get("https://api.amancredits.com/get-accounts", {
            params: {
              branch_id: branchId,
            },
          })
          .then((res) => {
            setAccountList(res.data.result);
          })
          .catch((err) => {
            console.error(err);
          });
      } catch (err) {
        console.error(err);
      }
    }
  }, [branchId]);
  useEffect(() => {
    if (balanceBefore !== null && ammount) {
      setBalanceAfter(parseInt(balanceBefore) - parseInt(ammount));
    }
  }, [ammount]);

  useEffect(() => {
    setBranchId(
      JSON.parse(JSON.parse(sessionStorage.getItem("aman-admin")).admin).id
    );
  }, []);
  return (
    <div>
      <main id="main" className="main">
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <div className="pagetitle">
          <h1>
            {lang.Investment_Loan_Management || "Investment/Loan Management"}
          </h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                >
                  <path d="m21.743 12.331-9-10c-.379-.422-1.107-.422-1.486 0l-9 10a.998.998 0 0 0-.17 1.076c.16.361.518.593.913.593h2v7a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-4h4v4a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-7h2a.998.998 0 0 0 .743-1.669z"></path>
                </svg>
              </li>
              <li className="breadcrumb-item">
                {lang.Investment_Loan_Management ||
                  "Investment/Loan Management"}
              </li>
              <li className="breadcrumb-item active">
                {lang.Withdrawal_Process || "Withdrawal Process"}
              </li>
            </ol>
          </nav>
        </div>
        <div className="card-body pt-3">
          <ul className="nav nav-tabs nav-tabs-bordered">
            <li className="nav-item">
              <Link to="/dashboard/investor-report">
                <button className="nav-link  ">
                  {lang.Investor_List || "Investor List"}
                </button>
              </Link>
            </li>

            <li className="nav-item">
              <Link to="/dashboard/investment-deposite">
                <button className="nav-link ">
                  {lang.Deposit_Process || "Deposit Process"}
                </button>
              </Link>
            </li>

            <li className="nav-item">
              <Link to="/dashboard/withdraw">
                <button className="nav-link active">
                  {lang.Withdrawal_Process || "Withdrawal Process"}
                </button>
              </Link>
            </li>
          </ul>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex mt-3 mb-3">
                    <h4 className="headingh4 fw-bold">
                      {lang.Withdrawal_Process || "Withdrawal Process"}
                    </h4>
                  </div>

                  <form>
                    <div className="d-flex  justify-content-end my-3">
                      <button
                        type="submit"
                        className="btn-cancel"
                        onClick={handleSubmit}
                      >
                        {lang.Save || "Save"}
                      </button>
                    </div>
                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Investor || "Investor"}:
                          </span>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            key={0}
                            onChange={(e) => {
                              setInvestor(e.target.value);
                              setBalanceBefore(
                                e.target.options[
                                  e.target.selectedIndex
                                ].getAttribute("amount") || 0
                              );
                            }}
                          >
                            <option selected disabled>
                              {lang.Investor || "Investor"}
                            </option>
                            {investorList.map((investor, index) => (
                              <option
                                key={index}
                                value={investor.id}
                                amount={investor.balance || 0}
                              >
                                {investor.FullName}
                              </option>
                            ))}
                          </select>
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-4">
                        {/* Bind input fields to state variables */}
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Account_for_Payments ||
                              "Account for Payments"}
                            :
                          </span>
                          <select
                            class="form-select"
                            aria-label="Default select example"
                            onChange={(e) => {
                              setAccount(e.target.value);
                            }}
                            required
                          >
                            <option selected disabled>
                              {lang.Account_for_Payments ||
                                "Account for payments"}
                            </option>
                            {accountList.map((account) => (
                              <option
                                key={account.id}
                                value={account.account_number}
                              >
                                {account.account_name}
                              </option>
                            ))}
                          </select>
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Amount || "Amount"}:
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            value={ammount}
                            onChange={(e) => setAmmount(e.target.value)}
                          />
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Balance_Before || "Balance Before"}:
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            value={balanceBefore}
                            readOnly // Make this field read-only
                          />
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Balance_After || "Balance After"}:
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            value={balanceAfter}
                            onChange={(e) => setBalanceAfter(e.target.value)}
                          />
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-4">
                        <label htmlFor="" className="w-100"></label>
                        <span style={{ fontSize: "14px" }}>
                          {lang.Statement_Optional || "Statement (Optional)"}:
                        </span>
                        <textarea
                          className="form-control"
                          rows="5"
                          value={statement}
                          onChange={(e) => setStatement(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
