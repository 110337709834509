import React from "react";
import { Link } from "react-router-dom";

export default function Contracts({ lang }) {
  return (
    <>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>{lang.General_Management || "General Management"}</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                >
                  <path d="m21.743 12.331-9-10c-.379-.422-1.107-.422-1.486 0l-9 10a.998.998 0 0 0-.17 1.076c.16.361.518.593.913.593h2v7a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-4h4v4a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-7h2a.998.998 0 0 0 .743-1.669z"></path>
                </svg>
              </li>

              <li className="breadcrumb-item">
                {lang.General_Management || "General Management"}
              </li>
              <li className="breadcrumb-item active">
                {lang.Contracts || "Contracts"}
              </li>
            </ol>
          </nav>
        </div>
        <section className="section">
          <div className="row">
            <h4 className="rr col-9 mt-5">{lang.Contracts || "Contracts"}</h4>

            <div className="col-lg-12">
              <div className="row">
                <div className="col-xxl-4 col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <Link to="basic-information" className="report-ref">
                        <img src="/img/folder.png" alt="logo" />
                        <span className="report-icon ms-2">
                          {lang.New_Installment_Contracts ||
                            "New Installments Contracts"}
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-xxl-4 col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <Link to="future/basic-information">
                        <img src="/img/folder.png" alt="logo" />
                        <span className="report-icon ms-2">
                          {lang.New_Future_Contracts || "New Futures Contract"}
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-xxl-4 col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <Link to="valid-contract">
                        <img src="/img/folder.png" alt="logo" />
                        <span className="report-icon ms-2">
                          {lang.Valid_Contract || "Valid Contract"}
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="row">


                                <div className="col-xxl-4 col-md-4">
                                    <div className="card">
                                        <div className="card-body">


                                            <Link to="/list-of-contract">
                                                <img src="/img/folder.png" alt="logo" />
                                                <span className="report-icon ms-2">{lang.Collections_and_Payments || "Collection & Payment"}</span>
                                            </Link>


                                        </div>

                                    </div>
                                </div>
                                <div className="col-xxl-4 col-md-4">
                                    <div className="card">
                                        <div className="card-body">

                                            <Link to="/dealer">
                                                <img src="/img/folder.png" alt="logo" />
                                                <span className="report-icon ms-2">{lang.Defaulted_Contracts || "Defaulted Contracts"}</span>
                                            </Link>

                                        </div>

                                    </div>

                                </div>
                                <div className="col-xxl-4 col-md-4">
                                    <div className="card">
                                        <div className="card-body">


                                            <Link to="/loan">
                                                <img src="/img/folder.png" alt="logo" />
                                                <span className="report-icon ms-2">{lang.Contract_Profits || "Contract Profits"}</span>
                                            </Link>


                                        </div>

                                    </div>
                                </div>
                            </div> */}

              {/* <div className="row">


                                
                                <div className="col-xxl-4 col-md-4">
                                    <div className="card">
                                        <div className="card-body">

                                            <Link to="/item">
                                                <img src="/img/folder.png" alt="logo" />
                                                <span className="report-icon ms-2">{lang.Pure_Contracts || "Pure Contracts"}</span>
                                            </Link>

                                        </div>

                                    </div>

                                </div>
                                <div className="col-xxl-4 col-md-4">
                                    <div className="card">
                                        <div className="card-body">

                                            <Link to="/intsallment-payment">
                                                <img src="/img/folder.png" alt="logo" />
                                                <span className="report-icon ms-2">{lang.Installments_and_Payments || "Installments & Payments"}</span>
                                            </Link>

                                        </div>

                                    </div>

                                </div>
                            </div> */}
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
