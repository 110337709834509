/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import IndeterminateCheckbox from "../components/IndeterminateChechbox";
import Table from "../components/Table";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

export default function Customer({ lang }) {
  const [customer, setCustomer] = useState([]);
  const [customerToDelete, setCustomerToDelete] = useState();
  const [customerID, setCustomerID] = useState();
  const [verifyCustomer, setVerifyCustomer] = useState();
  const [branchId, setBranchId] = useState();

  const navigate = useNavigate();

  const returnTable = async (table) => {
    await setCustomerToDelete(table.map((item) => item.original.id));
  };

  const handleSearch = () => {
    axios
      .post("https://api.amancredits.com/verify-customer", {
        validId: customerID,
      })
      .then((res) => {
        console.log(res.data.result);
        setVerifyCustomer(res.data.result);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchCustomer = () => {
    try {
      axios
        .get("https://api.amancredits.com/get-customers", {
          params: {
            branch_id: branchId,
          },
        })
        .then((res) => {
          setCustomer(res.data.result);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const columns = [
    {
      id: "select",
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
    },
    {
      header: lang.Customer_Name || "Customer Name",
      accessorKey: "FullName",
    },
    {
      header: lang.Identity_Number || "Identity Number",
      accessorKey: "ValidNationalID",
    },
    {
      header: lang.Occupation || "Occupation",
      accessorKey: "Occupation",
    },
    {
      header: lang.Mobile_Number || "Mobile Number",
      accessorKey: "MobilePhoneNo",
    },
    {
      header: lang.Status || "Status",
      cell: (row) =>
        row.row.original.contracts[0] &&
        row.row.original.contracts[0].contract_status == 1 ? (
          <span class="badge rounded-pill bg-success">
            {lang.active || "Active"}
          </span>
        ) : row.row.original.contracts[0] &&
          row.row.original.contracts[0].contract_status == 2 ? (
          <span class="badge rounded-pill bg-primary">
            {lang.completed || "Completed"}
          </span>
        ) : row.row.original.contracts[0] &&
          row.row.original.contracts[0].contract_status == 3 ? (
          <span class="badge rounded-pill bg-danger">
            {lang.defaulted || "Defaulted"}
          </span>
        ) : (
          <span class="badge rounded-pill bg-warning">
            {lang.no_contract || "No Contract"}
          </span>
        ),
    },
    {
      header: lang.Actions || "Actions",
      cell: (row) => (
        <div className="dropdown top-0 start-0">
          <button
            className="btn btn-primary dropdown-toggle"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {lang.Actions || "Actions"}
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <button
                className="dropdown-item mt-0"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("profile/customer/" + row.row.original.customer_id);
                }}
              >
                {lang.Profile || "Profile"}
              </button>
            </li>
            <li>
              <button
                className="dropdown-item mt-0"
                disabled={
                  !(
                    row.row.original.contracts[0] &&
                    row.row.original.contracts[0].contract_status
                  )
                    ? true
                    : false
                }
                onClick={(e) => {
                  e.preventDefault();
                  navigate(
                    "contract-information/" +
                      row.row.original.contracts[0].contract_id
                  );
                }}
              >
                {lang.contract || "Contract"}
              </button>
            </li>
            <li>
              <button
                className="dropdown-item mt-0"
                onClick={() => {
                  let phoneNo = row.row.original.MobilePhoneNo.toString();
                  let waNum = "+966" + phoneNo.slice(1);
                  window.open("https://wa.me/" + waNum, "_blank");
                }}
              >
                {lang.whatsapp || "WhatsApp"}
              </button>
            </li>
            <li>
              <button
                className="dropdown-item mt-0"
                onClick={() => {
                  const formData = {
                    customer: row.row.original.customer_id
                  }
                  sessionStorage.setItem("formData", JSON.stringify(formData));
                  navigate("/dashboard/contracts/identification");
                }}
              >
                {lang.create_contract || "Create Contract"}
              </button>
            </li>
          </ul>
        </div>
        // <span className="d-flex justify-content-between">
        //   <button
        //     className="btn btn-primary mx-1"
        //     onClick={(e) => {
        //       e.preventDefault();
        //       navigate("profile/customer/" + row.row.original.customer_id);
        //     }}
        //   >
        //     <svg
        //       xmlns="http://www.w3.org/2000/svg"
        //       height="16"
        //       width="18"
        //       fill="white"
        //       viewBox="0 0 576 512"
        //     >
        //       <path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z" />
        //     </svg>
        //   </button>
        //   <button
        //     className="btn btn-primary mx-1"
        //     disabled={
        //       !(
        //         row.row.original.contracts[0] &&
        //         row.row.original.contracts[0].contract_status
        //       )
        //         ? true
        //         : false
        //     }
        //     onClick={(e) => {
        //       e.preventDefault();
        //       navigate(
        //         "contract-information/" +
        //           row.row.original.contracts[0].contract_id
        //       );
        //     }}
        //   >
        //     <svg
        //       xmlns="http://www.w3.org/2000/svg"
        //       height="16"
        //       width="12"
        //       viewBox="0 0 384 512"
        //       fill="white"
        //     >
        //       <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM80 64h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm54.2 253.8c-6.1 20.3-24.8 34.2-46 34.2H80c-8.8 0-16-7.2-16-16s7.2-16 16-16h8.2c7.1 0 13.3-4.6 15.3-11.4l14.9-49.5c3.4-11.3 13.8-19.1 25.6-19.1s22.2 7.7 25.6 19.1l11.6 38.6c7.4-6.2 16.8-9.7 26.8-9.7c15.9 0 30.4 9 37.5 23.2l4.4 8.8H304c8.8 0 16 7.2 16 16s-7.2 16-16 16H240c-6.1 0-11.6-3.4-14.3-8.8l-8.8-17.7c-1.7-3.4-5.1-5.5-8.8-5.5s-7.2 2.1-8.8 5.5l-8.8 17.7c-2.9 5.9-9.2 9.4-15.7 8.8s-12.1-5.1-13.9-11.3L144 349l-9.8 32.8z" />
        //     </svg>
        //   </button>
        //   <button className="btn btn-primary mx-1" onClick={() => {
        //     let phoneNo = (row.row.original.MobilePhoneNo).toString()
        //     let waNum = "+966" + phoneNo.slice(1)
        //     window.open('https://wa.me/' + waNum, '_blank');
        //     console.log(waNum)
        //   }}>
        //     <svg
        //       xmlns="http://www.w3.org/2000/svg"
        //       width="24"
        //       height="24"
        //       viewBox="0 0 24 24"
        //       fill="#fff"
        //     >
        //       <path
        //         fill-rule="evenodd"
        //         clip-rule="evenodd"
        //         d="M18.403 5.633A8.919 8.919 0 0 0 12.053 3c-4.948 0-8.976 4.027-8.978 8.977 0 1.582.413 3.126 1.198 4.488L3 21.116l4.759-1.249a8.981 8.981 0 0 0 4.29 1.093h.004c4.947 0 8.975-4.027 8.977-8.977a8.926 8.926 0 0 0-2.627-6.35m-6.35 13.812h-.003a7.446 7.446 0 0 1-3.798-1.041l-.272-.162-2.824.741.753-2.753-.177-.282a7.448 7.448 0 0 1-1.141-3.971c.002-4.114 3.349-7.461 7.465-7.461a7.413 7.413 0 0 1 5.275 2.188 7.42 7.42 0 0 1 2.183 5.279c-.002 4.114-3.349 7.462-7.461 7.462m4.093-5.589c-.225-.113-1.327-.655-1.533-.73-.205-.075-.354-.112-.504.112s-.58.729-.711.879-.262.168-.486.056-.947-.349-1.804-1.113c-.667-.595-1.117-1.329-1.248-1.554s-.014-.346.099-.458c.101-.1.224-.262.336-.393.112-.131.149-.224.224-.374s.038-.281-.019-.393c-.056-.113-.505-1.217-.692-1.666-.181-.435-.366-.377-.504-.383a9.65 9.65 0 0 0-.429-.008.826.826 0 0 0-.599.28c-.206.225-.785.767-.785 1.871s.804 2.171.916 2.321c.112.15 1.582 2.415 3.832 3.387.536.231.954.369 1.279.473.537.171 1.026.146 1.413.089.431-.064 1.327-.542 1.514-1.066.187-.524.187-.973.131-1.067-.056-.094-.207-.151-.43-.263"
        //       ></path>
        //     </svg>
        //   </button>
        // </span>
      ),
    },
  ];

  useEffect(() => {
    if (branchId) {
      fetchCustomer();
    }
  }, [branchId]);

  useEffect(() => {
    setBranchId(
      JSON.parse(JSON.parse(sessionStorage.getItem("aman-admin")).admin).id
    );
  }, []);

  useEffect(() => {
    if (customerToDelete) {
      console.log(customerToDelete);
      try {
        axios
          .put("https://api.amancredits.com/delete-user/customer", {
            idList: customerToDelete,
          })
          .then((res) => {
            console.log(res.data.Message);
            fetchCustomer();
          })
          .catch((err) => {
            console.error("error");
          });
      } catch (err) {
        console.error(err);
      }
    }
  }, [customerToDelete]);
  return (
    <div>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>{lang.Customer_Management || "Customer Management"}</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                >
                  <path d="m21.743 12.331-9-10c-.379-.422-1.107-.422-1.486 0l-9 10a.998.998 0 0 0-.17 1.076c.16.361.518.593.913.593h2v7a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-4h4v4a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-7h2a.998.998 0 0 0 .743-1.669z"></path>
                </svg>
              </li>
              <li className="breadcrumb-item">
                {lang.Customer_Management || "Customer Management"}
              </li>
              <li className="breadcrumb-item active">
                {lang.Customer_List || "Customer List"}
              </li>
            </ol>
          </nav>
        </div>

        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="row justify-content-end w-100">
                  <div className="col-md-4 col-lg-3">
                    <a
                      href="https://www.absher.sa/wps/portal/individuals/Home/homepublic/!ut/p/z1/04_Sj9CPykssy0xPLMnMz0vMAfIjo8ziDTxNTDwMTYy83Q3MjAwcw4IsTFw9TQ3dzUz0wwkpiAJJ4wCOBkD9UViUOBo4BRk5GRsYuPsbYVWAYkZBboRBpqOiIgBIR9Vv/dz/d5/L0lDUmlTUSEhL3dHa0FKRnNBLzROV3FpQSEhL2Fy/"
                      target="_blank"
                      className="btn-cancel btn"
                    >
                      <img
                        src="/img/absherlogo.svg"
                        width={48}
                        height={48}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="col-md-4 col-lg-3">
                    <a
                      href="https://new.najiz.sa/applications/landing"
                      target="_blank"
                      className="btn-cancel btn"
                    >
                      <img
                        src="/img/Najizlogo.svg"
                        width={48}
                        height={48}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="col-md-4 col-lg-3">
                    <a
                      href="https://www.gosi.gov.sa/ar"
                      target="_blank"
                      className="btn-cancel btn"
                    >
                      <img
                        src="/img/GOSIlogo.png"
                        width={48}
                        height={48}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="col-md-4 col-lg-3">
                    <button
                      className="btn-cancel btn"
                      data-bs-toggle="modal"
                      data-bs-target="#goodsverticalycenteredexpense"
                    >
                      {lang.verify_customer || "Verify Customer"}
                    </button>
                  </div>
                  <div className="col-md-4 col-lg-3 mt-4">
                    <Link to="add-customer">
                      <button type="button" className="btn btn-primary">
                        <img
                          src="img/fi_plus.png"
                          alt=""
                          className="img-fluid"
                        />
                        {lang.Add_New_Customer || "Add New Customer"}
                      </button>
                    </Link>
                  </div>
                </div>

                <div className="row pt-3">
                  <div className="rr col-9">
                    {lang.Customer_List || "Customer List"}
                  </div>
                </div>
                <div className="card-body">
                  <div classNames="d-flex mt-3 mb-3">
                    <Table
                      lang={lang}
                      data={customer}
                      columns={columns}
                      returnTable={returnTable}
                      deleteButton={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="goodsverticalycenteredexpense"
            tabindex="-1"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSearch();
                    }}
                  >
                    <div className="row mb-3 mt-5 d-flex justify-content-center">
                      <div className="col-sm-8 mb-3">
                        <label htmlFor="expenseCategory" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Customer_ValidId ||
                              "Customer Valid Identity Number"}
                          </span>
                          <input
                            id="expenseCategory"
                            type="text"
                            className="form-control"
                            // placeholder="Search"
                            value={customerID}
                            onChange={(e) => setCustomerID(e.target.value)}
                            required
                          />
                        </label>
                      </div>
                    </div>

                    <div className="text-center mb-5">
                      <button type="submit" className="btn-cancel">
                        {lang.Search || "Search"}
                      </button>
                    </div>
                  </form>
                  {verifyCustomer && verifyCustomer.length === 0 && (
                    <h4>
                      {lang.Customer_not_exists || "Customer does not exists"}
                    </h4>
                  )}
                  {verifyCustomer && verifyCustomer.length !== 0 && (
                    <div>
                      <div class="row mb-3 d-flex justify-content-center">
                        <div className="row mb-3 ">
                          <div className="">
                            <span className="fw-bold">
                              {lang.Full_Name || "Full Name"}:
                            </span>
                            {verifyCustomer[0].FullName}
                          </div>
                        </div>
                      </div>
                      <div class="row mb-3 d-flex justify-content-center">
                        <div className="row mb-3 ">
                          <div className="">
                            <span className="fw-bold">
                              {lang.Valid_Identity_Number ||
                                "Valid Identity Number"}
                              :
                            </span>{" "}
                            {verifyCustomer[0].ValidNationalID}
                          </div>
                        </div>
                      </div>
                      {/* <div class="row mb-3 d-flex justify-content-center">
                        <div className="row mb-3 ">
                          <div className="">
                            <span className="fw-bold">{lang.branch_name || "Office Name"}:</span>{" "}
                            {verifyCustomer[0].branch_name}
                          </div>
                        </div>
                      </div> */}
                      <div class="row mb-3 d-flex justify-content-center">
                        <div className="row mb-3 ">
                          <div className="">
                            <span className="fw-bold">
                              {lang.Status || "Contract Status"}:
                            </span>{" "}
                            {verifyCustomer[0].status == 1
                              ? lang.in_progress || "In-Progress"
                              : verifyCustomer[0].status == 2
                              ? lang.completed || "Completed"
                              : verifyCustomer[0].status == 3
                              ? lang.defaulted || "Defaulted"
                              : "No Contract"}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
