import axios from "axios";
import React, { useEffect, useState } from "react";
import Table from "../components/Table";
import IndeterminateCheckbox from "../components/IndeterminateChechbox";
import { ToastContainer, toast } from "react-toastify";

function Expenses({ lang }) {
  const [expenseList, setExpenseList] = useState([]);
  const [expenseCategories, setExpenseCategories] = useState([]);
  const [expense, setExpense] = useState("");
  const [accountList, setAccountList] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [amount, setAmount] = useState("");
  const [balanceBefore, setBalanceBefore] = useState(0); // Initialize with a default value
  const [balanceAfter, setBalanceAfter] = useState(0);
  const [statement, setStatement] = useState("");
  const [expenseCategory, setExpenseCategory] = useState("");
  const [expensesToDelete, setExpensesToDelete] = useState();
  const [branchId, setBranchId] = useState();

  const handleSubmit = () => {
    try {
      axios
        .post("https://api.amancredits.com/add-expense", {
          expense_category: expense,
          account: selectedAccount.id,
          amount: amount,
          statement: statement,
          branch_id: branchId,
        })
        .then((res) => {
          console.log(res.data);
          setExpense();
          setSelectedAccount();
          setAmount();
          setBalanceBefore();
          setBalanceAfter();
          setStatement();
          toast.success(res.data.Message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          axios
            .get("https://api.amancredits.com/get-expenses", {
              params: {
                branch_id: branchId,
              },
            })
            .then((res) => {
              setExpenseList(res.data.result);
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch((err) => {
          console.error(err);
          toast.error("Some error occured", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    } catch (err) {
      console.error(err);
      toast.error("Some error occured", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleExpenseCatSubmit = (e) => {
    e.preventDefault();
    try {
      axios
        .post("https://api.amancredits.com/add-expense-category", {
          expense: expenseCategory,
          branch_id: branchId,
        })
        .then((res) => {
          console.log(res);
          setExpenseCategory("");
          toast.success(res.data.Message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          axios
            .get("https://api.amancredits.com/get-expense-category", {
              params: {
                branch_id: branchId,
              },
            })
            .then((res) => {
              setExpenseCategories(res.data.result);
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch((err) => {
          console.log(err);
          toast.error("Some error occured", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    } catch (err) {
      console.error(err);
      toast.error("Some error occured", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const returnTable = async (table) => {
    // await setAccountListToDelete(table.map(item => item.original.id))
    setExpensesToDelete(table.map((item) => item.original.id));
  };
  const columns = [
    {
      id: "select",
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
    },
    {
      header: lang.Expense_Category || "Expense Category",
      accessorKey: "expense",
    },
    {
      header: lang.Amount || "Amount",
      accessorKey: "amount",
    },
  ];

  useEffect(() => {
    if(branchId){
      try {
        axios
          .get("https://api.amancredits.com/get-expenses", {
            params: {
              branch_id: branchId,
            },
          })
          .then((res) => {
            setExpenseList(res.data.result);
          })
          .catch((err) => {
            console.error(err);
          });
        axios
          .get("https://api.amancredits.com/get-expense-category", {
            params: {
              branch_id: branchId,
            },
          })
          .then((res) => {
            setExpenseCategories(res.data.result);
          })
          .catch((err) => {
            console.error(err);
          });
        axios
          .get("https://api.amancredits.com/get-accounts", {
            params: {
              branch_id: branchId,
            },
          })
          .then((res) => {
            setAccountList(res.data.result);
          })
          .catch((err) => {
            console.error(err);
          });
      } catch (err) {
        console.error(err);
      }
    }
  }, [branchId]);

  useEffect(() => {
    setBranchId(
      JSON.parse(JSON.parse(sessionStorage.getItem("aman-admin")).admin).id
    );
  }, []);

  useEffect(() => {
    // Update balanceAfter whenever amount or balanceBefore changes
    const calculatedBalanceAfter =
      parseFloat(balanceBefore) - parseFloat(amount);
    setBalanceAfter(
      isNaN(calculatedBalanceAfter) ? "" : calculatedBalanceAfter.toFixed(2)
    );
  }, [amount, balanceBefore]);

  useEffect(() => {
    if (expensesToDelete) {
      axios
        .delete("https://api.amancredits.com/delete-expense", {
          data: { idList: expensesToDelete }, // Include the payload in the 'data' property
        })
        .then((res) => {
          console.log(res.data.Message);
          axios
            .get("https://api.amancredits.com/get-expenses")
            .then((res) => {
              setExpenseList(res.data.result);
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [expensesToDelete]);

  const handleAccountChange = (e) => {
    const selectedAccountId = e.target.value;
    const selectedAccountObject = accountList.find(
      (account) => account.id === parseInt(selectedAccountId)
    );

    // Update selected account and balance before
    setSelectedAccount(selectedAccountObject);
    setBalanceBefore(
      selectedAccountObject ? selectedAccountObject.balance : "Balance Before"
    );
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  return (
    <div>
      <main className="main" id="main">
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <div className="pagetitle">
          <h1>{lang.General_Management || "General Management"}</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                >
                  <path d="m21.743 12.331-9-10c-.379-.422-1.107-.422-1.486 0l-9 10a.998.998 0 0 0-.17 1.076c.16.361.518.593.913.593h2v7a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-4h4v4a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-7h2a.998.998 0 0 0 .743-1.669z"></path>
                </svg>
              </li>
              <li className="breadcrumb-item">
                {lang.General_Management || "General Management"}
              </li>
              <li className="breadcrumb-item active">
                {lang.Expense_List || "Expense List"}
              </li>
            </ol>
          </nav>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="row pt-3">
                  <div className="rr col-9">
                    {lang.Expenses_List || "Expenses List"}
                  </div>
                </div>
                <div className="card-body">
                  <div classNames="d-flex mt-3 mb-3">
                    {expenseList && (
                      <Table
                        lang={lang}
                        data={expenseList}
                        columns={columns}
                        returnTable={returnTable}
                        deleteButton={true}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex mt-3 mb-3">
                    <h4 className="headingh4 fw-bold">
                      {lang.Add_Expense || "Add Expense"}
                    </h4>
                  </div>

                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    <div className="d-flex mt-3 mb-3 justify-content-end">
                      <button type="submit" className="btn-save">
                        {lang.Save_And_Publish || "Save & Publish"}
                      </button>
                      <button
                        className="btn-cancel ms-4"
                        data-bs-toggle="modal"
                        data-bs-target="#goodsverticalycenteredexpense"
                      >
                        {lang.Add_Category || "Add Category"}
                      </button>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-4">
                        <label htmlFor="account" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Account || "Account"}:
                          </span>
                          <select
                            id="account"
                            className="form-select"
                            aria-label="Default select example"
                            required
                            onChange={handleAccountChange}
                          >
                            <option selected disabled>
                              {lang.Account || "Account"}
                            </option>
                            {accountList.map((account) => (
                              <option key={account.id} value={account.id}>
                                {account.account_name}
                              </option>
                            ))}
                          </select>
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-4">
                        <label htmlFor="expenseCategory" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Expense_Category || "Expense Category"}:
                          </span>
                          <select
                            id="expenseCategory"
                            className="form-select"
                            aria-label="Default select example"
                            onChange={(e) => setExpense(e.target.value)}
                            required
                          >
                            <option defaultValue="">
                              {lang.Expense_Category || "Expense Category"}
                            </option>
                            {expenseCategories.map((expense) => (
                              <option key={expense.id} value={expense.id}>
                                {expense.expense}
                              </option>
                            ))}
                          </select>
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-4">
                        <label htmlFor="amount" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Amount || "Amount"}:
                          </span>
                          <input
                            type="text"
                            id="amount"
                            className="form-control"
                            onChange={(e) => handleAmountChange(e)}
                            required
                          />
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-4">
                        <label htmlFor="statement" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Statement_Optional || "Statement (Optional)"}:
                          </span>
                          <textarea
                            id="statement"
                            type="text"
                            className="form-control"
                            placeholder="(Optional)"
                            rows={5}
                            value={statement}
                            onChange={(e) => setStatement(e.target.value)}
                          />
                        </label>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          className="modal fade"
          id="goodsverticalycenteredexpense"
          tabindex="-1"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <form onSubmit={handleExpenseCatSubmit}>
                  <div className="row mb-3 mt-5 d-flex justify-content-center">
                    <div className="col-sm-8 mb-3">
                      <label htmlFor="expenseCategory" className="w-100">
                        <span style={{ fontSize: "14px" }}>
                          {lang.Expense_Category || "Expense Category"}:
                        </span>
                        <input
                          id="expenseCategory"
                          type="text"
                          className="form-control"
                          placeholder="Expense Category"
                          value={expenseCategory}
                          onChange={(e) => setExpenseCategory(e.target.value)}
                          required
                        />
                      </label>
                    </div>
                  </div>

                  <div className="text-center mb-5">
                    <button
                      type="button"
                      className="btn-save"
                      data-bs-dismiss="modal"
                    >
                      {lang.Cancel || "Cancel"}
                    </button>
                    <button type="submit" className="btn-cancel">
                      {lang.Add_Now || "Add Now"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Expenses;
