import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useParams } from "react-router-dom";

function DealerChangePassword({ lang }) {
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [passwordError, setPasswordError] = useState(false);
  const { dealer_id } = useParams();

  const handlePassword = () => {
    if (passwordError) {
      toast.error("Passwords do not match", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }
    axios
      .put("https://api.amancredits.com/dealer-pass-update/" + dealer_id, {
        password: newPassword,
      })
      .then((res) => {
        setNewPassword("");
        setConfirmPassword("");
        toast.success(res.data.Message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (newPassword !== confirmPassword) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
  }, [confirmPassword]);
  return (
    <main id="main" className="main">
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className="pagetitle">
        <h1>{lang.Dealer_Management || "Dealer Management"}</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 24 24"
              >
                <path d="m21.743 12.331-9-10c-.379-.422-1.107-.422-1.486 0l-9 10a.998.998 0 0 0-.17 1.076c.16.361.518.593.913.593h2v7a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-4h4v4a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-7h2a.998.998 0 0 0 .743-1.669z"></path>
              </svg>
            </li>
            <li className="breadcrumb-item">
              {lang.Dealer_Management || "Dealer Management"}
            </li>
            <li className="breadcrumb-item active">
              {lang.dealer_list || "Dealer List"}
            </li>
          </ol>
        </nav>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="d-flex mt-3 mb-3">
                  <h4 className="headingh4 fw-bold">
                    {lang.change_password || "Change Password"}
                  </h4>
                </div>

                <form
                  action=""
                  onSubmit={(e) => {
                    e.preventDefault();
                    handlePassword();
                  }}
                >
                  <div className="d-flex mt-3 mb-3">
                    <h4 class="headingh4 fw-bold">
                      {lang.Password || "Password"}
                    </h4>
                  </div>
                  <div className="d-flex mt-3 mb-3 justify-content-end">
                    <button type="submit" className="btn-cancel">
                      {lang.Update_Text || "Update"}
                    </button>
                  </div>

                  <div className="row mb-3 d-flex justify-content-center">
                    <div className="col-sm-6">
                      <label htmlFor="" className="w-100">
                        <span style={{ fontSize: "14px" }}>
                          {lang.new_password || "New Password"}
                        </span>
                        <input
                          type="password"
                          className="form-control"
                          required
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="row mb-3 d-flex justify-content-center">
                    <div className="col-sm-6">
                      <label htmlFor="" className="w-100">
                        <span style={{ fontSize: "14px" }}>
                          {lang.confirm_password || "Confirm Password"}
                        </span>
                        <input
                          type="password"
                          className="form-control"
                          required
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </label>
                    </div>
                  </div>

                  {passwordError && (
                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-sm-6">
                        <div class="alert alert-danger" role="alert">
                          Passwords do not match
                        </div>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default DealerChangePassword;
