import React, { useEffect, useState, useRef } from "react";
import ContractViewer from "../components/ContractViewer";
// import { contractFormat } from "../components/ContractFormat";
import "../style/ContractForm.css";
import "../style/OathForm.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useReactToPrint } from 'react-to-print';

function ContractForm({ lang, load, contractFormat }) {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <div className="row pt-3">
        <div className="rr col-9">{lang.Contract || "Contract"}</div>
      </div>
      <div className="row">
        <div className="col-9"></div>
        <div className="col-3">
          <div></div>
          <div>
            <button
              type="button"
              // onClick={() => toPDF()}
              onClick={handlePrint}
              className="btn btn-primary"
            >
              {lang.save_contract || "Save Contract"}
            </button>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div classNames="d-flex mt-3 mb-3">
          <div
            className="w-100 contarct-form pt-5"
            id="contract-form"
            // ref={targetRef}
            ref={componentRef}
          >
            {load && <ContractViewer htmlContent={contractFormat} />}
          </div>
        </div>
      </div>
    </>
  );
}

function OathForm({ lang, oathFormat }) {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <div className="row pt-3">
        <div className="rr col-9">{lang.oath_form || "Oath Form"}</div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-9"></div>
          <div className="col-3">
            <div></div>
            <div to="/add-customer">
              <button
                type="button"
                // onClick={() => toPDF()}
                onClick={handlePrint}
                className="btn btn-primary"
              >
                <img src="img/fi_plus.png" alt="" className="img-fluid" />
                {lang.save_oath || "Save Oath"}
              </button>
            </div>
          </div>
        </div>
        <div classNames="d-flex">
          <div
            className="w-100 oath-form"
            style={{ padding: "2rem 0" }}
            id="oath-form"
            // ref={targetRef}
            ref={componentRef}
          >
            <ContractViewer htmlContent={oathFormat} />
          </div>
        </div>
      </div>
    </>
  );
}

function PolicyForm({ lang, placeholders }) {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <div className="row pt-3">
        <div className="rr col-9">{lang.policy || "Policy"}</div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-9"></div>
          <div className="col-3">
            <div></div>
            <div to="/add-customer">
              <button
                type="button"
                // onClick={() => toPDF()}
                onClick={handlePrint}
                className="btn btn-primary"
              >
                <img src="img/fi_plus.png" alt="" className="img-fluid" />
                {lang.save_policy || "Save Policy"}
              </button>
            </div>
          </div>
        </div>
        <div classNames="d-flex mt-3 mb-3">
          <div
            className="w-100"
            style={{ padding: "2rem 0" }}
            id="policy-form"
            // ref={targetRef}
            ref={componentRef}
          >
            {placeholders && (
              <div dir="rtl" style={{ padding: "2rem" }}>
                <p
                  style={{
                    fontSize: "1rem",
                    textDecoration: "underline",
                    marginBottom: "3rem",
                  }}
                >
                  الموافقة على شروط استخدام برنامج امان القروض الخاص بإدارة
                  الأقساط
                </p>
                <p style={{ fontSize: "1rem" }}>
                  الإسم : {placeholders.customer_name}
                </p>
                <p style={{ fontSize: "1rem" }}>
                  رقم الهوية : {placeholders.customer_id}
                </p>
                <p style={{ fontSize: "1rem" }}>
                  جوال: {placeholders.customer_phone}
                </p>
                <p style={{ fontSize: "1rem" }}>التوقيع :</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

function ContractInformation({ lang }) {
  const [load, setLoad] = useState(true);
  const [placeholders, setPlaceholders] = useState();
  const [officeName, setOfficeName] = useState("");
  const { customer_id } = useParams();

  function convertDateFormat(inputDate, locale) {
    const dateObject = new Date(inputDate);
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };

    if (locale !== "ar-SA") {
      // Format date as YYYY-MM-DD for English locale
      const year = dateObject.getFullYear();
      const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
      const day = dateObject.getDate().toString().padStart(2, "0");
      return `${day}-${month}-${year}`;
    }

    const formattedDate = dateObject.toLocaleDateString(locale, options);
    return formattedDate;
  }

  const contractFormat =
    placeholders &&
    `
    <main style="width: 800px;
    margin: 0 auto;
    padding: 4rem 1rem;
    border: 2px solid black;
    border-radius: 150px;
    font-family: 'Times New Roman';">
        <div class="main">
            <div class="main-heading">
                <span>عقد بيع بالإجل</span>
            </div>
        </div>
        <div class="dates">
            <div class="right" style="width: 33%;">
                <h3 style="font-size: 16px;">رقم العقد <span style="color: black;">${
                  placeholders.contract_form_number || ""
                }</span></h3>
            </div>
            <div class="left" style="width: 33%;">
                <div>
                    <h3 style="font-size: 16px;">أنه في يوم <span style="color: black;">${
                      convertDateFormat(placeholders.date_contract, "ar-SA") ||
                      ""
                    }</span></h3>
                </div>
            </div>
            <div style="width: 33%;">
                <h3 style="font-size: 16px;"> الموافق <span style="color: black;">${
                  convertDateFormat(placeholders.date_contract) || ""
                }</span></h3>
            </div>
        </div>

        <div class="start">
            <div class="start1">
                <h3 style="font-size: 16px;"> الحمد لله والصلاة والسلام على من لانبي بعده......وبعد :</h3>
            </div>
            <div class="start2">
                <h3 style="font-size: 16px;"> فأقر انا الموقع أدناه الطرف الثاني / <span style="color: black;">${
                  placeholders.customer_name || ""
                }</span></h3>
                
            </div>
        </div>

        <div class="second-dates">
            <div class="left2">
                <div>
                    <h3 style="font-size: 16px;">تاريخ الميلاد : <span style="color: black;">${
                      convertDateFormat(placeholders.customer_DoB) || ""
                    }</span></h3>
                  
                </div>
                <div>
                    <h3 style="font-size: 16px;">بطاقة أحوال رقم / <span style="color: black;">${
                      placeholders.customer_id || ""
                    }</span></h3>
                </div>
            </div>
        </div>

        <div class="third-dates">
            <div class="left3">
                <div style='width: 50%;'>
                    <h3 style="font-size: 16px;">بتاريخ: <span style="color: black;">${
                      convertDateFormat(placeholders.customer_releasedate) || ""
                    }</span></h3>
                    
                </div>
                <div style='width: 50%;'>
                    <h3 style="font-size: 16px;"> مصدرها أحوال / <span style="font-size: 16px;">${
                      placeholders.customer_source || ""
                    }</span></h3>
                  
                </div>
            </div>

            <div class="right3">
                <div>
                    <h3 style="font-size: 16px;"> جوال /</h3>
                </div>
                <div>
                    <h3 style="font-size: 16px;" style="color: red">/ العنوان</h3>
                </div>
            </div>
        </div>

        <div class="fourth-div">
            <h3 style="font-size: 16px;">
                وأنا بكامل الأوصاف المعتبرة شرعاً : بأنني اشتريت من الطرف الاول
            / ${officeName}</h3>
        </div>
        <div>
        <p style="font-size: 16px;">${
          placeholders.description || "Description"
        }</p>
        </div>
        <div class="fifth-div">
            <h2 style="font-size: 16px;"><span> بمبلغ وقدره </span> (<span style="border-bottom: 1px solid black;
            border-style: dotted;
            width: 8rem;
            display: inline-block;
            text-align: center;
            border-top: none;
            border-left: none;
            border-right: none;
            font-size: 16px;">${
              placeholders.total_value + placeholders.legal_fees || ""
            }</span>) رس</h2>
        </div>

        <div class="sixth-div">
            <h3 style="font-size: 16px;"> تسدد على دفعة واحدة بتاريخ : <span style="font-size: 16px;">${
              convertDateFormat(placeholders.date_1st_installment) || ""
            }</span></h3>
        </div>

        <div class="seventh-div">
            <h3 style="font-size: 16px;">
                هذا وقد استلمت البضاعة على حالتها الراهنة وقبلتها بعد معاينتها بما
                فيها من عيوب ظاهرة وباطنة بما ينفي الجهالة أو الغرر أو الغبن
            </h3>
        </div>
        <div class="eight-div">
            <h3 style="font-size: 16px;">
                وقد اتفق الطرفان في حالة تأخر المشتري عن سداد قيمة المبيع في الوقت
                المتفق عليه فإن للطرف الأول الحق بالإتجاه إلى الجهات المختصة لإقامة
                الدعوى في المحكمة المختصة بمدينة ${
                  placeholders.judgement_city
                } (شرط ملزم للطرفين )
            </h3>
            <!-- <h3 id="float">لإقامة  الدعوى في المحكمة المختصة بمدينة حائل (شرط ملزم للطرفين )</h3> -->
        </div>

        <div class="ninth-div">
            <h3 style="font-size: 16px;"> ملاحظات :</h3>
        </div>

        <div class="ten-div">
            <span class="span"></span><h3 style="font-size: 16px;"><span style="font-size: 16px;"> إقرار الكفيل</span>(الضامن) </h3><span class="span"></span>
        </div>

        <div class="eleven-div">
            <div class="eleven1">
                <h3 style="font-size: 16px;"> اقر واتعهد أنا المكرم/ <span style="font-size: 16px;">${
                  placeholders.guarantors || ""
                }</span></h3>
            </div>
            <div class="eleven2">
                <h3 style="font-size: 16px;" id="div11"> بطاقة أحوال رقم ال / <span style="font-size: 16px;">${
                  placeholders.guarantor_Vid || ""
                }</span></h3>
                
            </div>
        </div>

        <div class="twelve-div">
            <div class="twelve2">
                <h3 style="font-size: 16px;">بتاريخ <span style="font-size: 16px;">${
                  placeholders.guarantor_releaseDate !== null
                    ? convertDateFormat(placeholders.guarantor_releaseDate)
                    : ""
                }</span></h3>
            </div>
            <div class="twelve1">
                <div>
                    <h3 style="font-size: 16px;">مصدرها أحوال / <span style="font-size: 16px;">${
                      placeholders.guarantor_idSource || ""
                    }</span></h3>
                  
                </div>
                <div>
                    <h3 style="font-size: 16px;"> هاتف رقم/ <span style="font-size: 16px;">${
                      placeholders.guarantor_phone || ""
                    }</span></h3>
                    
                </div>
            </div>
          
        </div>


        <div class="thirth-div">
        <p style="font-size: 16px;">وأنا بكامل الأوصاف المعتبرة شرعاً : <br>

        
          
            <span style="color: red;"> بأني أكفل وأضمن الطرف الثاني السيد / </span> <span style="font-size: 16px; padding: 0 2rem;">${
              placeholders.customer_name || ""
            }</span>
            وذلك بتسديد ودفع قيمة العقد المطلوب
        
            <br>
        <span> <span style="color: red;"> للسيد/ </span><span style="border-bottom: 1px solid black;
            border-style: dotted;
            padding: 0px 2rem;
            display: inline-block;
            text-align: center;
            border-top: none;
            border-left: none;
            border-right: none;
            font-size: 16px;">${officeName || ""}</span>.</span>
            على المذكور حسب  الاتفاقية المبرمة بينهما بحيث أقوم بدفع كل قسط في حينه إذا
            تأخر مكفولي المذكور عن دفعه وألزم أيضاً ورثتي من بعدي في حال حدوث أمر لا سمح الله

            .والله خير الشاهدين , وصلى الله على محمد صلى الله عليه وسلم </p>
        </div>

    <hr>

    <div style="display: flex;">
        <div style="width: 33%;">
            <div class="eighteen1" style="text-align: center;">
                <h3 style="font-size: 16px;">الطرف الأول البائع</h3>
            </div>
            <div class="ninteen2">
                <h3 style="font-size: 16px;">الاســم / <span style="font-size: 16px;">${
                  officeName || ""
                }</span></h3>
            </div>
            <div class="twozero2">
                <h3 style="font-size: 16px;"> التوقيع /</h3>
            </div>
        </div>
        <div  style="width: 33%;">
            <div style="text-align: center; margin-top: 2rem;">
                <h3 style="font-size: 16px;">الختم</h3>
            </div>
        </div>
        <div style="width: 33%;">
            <div class="eighteen2" style="text-align: center;">
                <h3 style="font-size: 16px;">الطرف الثاني المشتري</h3>
            </div>
            <div>
                <h3 style="font-size: 16px;"> الاســم / <span style="font-size: 16px;">${
                  placeholders.customer_name || ""
                }</span></h3>   
            </div>
            <div class="twozero2">
                <h3 style="font-size: 16px;"> التوقيع /</h3>
            </div>
        </div>
    </div>

      <div style="display: flex; margin: 3rem 0;">
        <div style="width: 33%;">
            <div>
                <h3 style="font-size: 16px;">الشاهد الأول</h3>
            </div>
            <div>
                <h3 style="font-size: 16px;"> الاســم /</h3>   
            </div>
            <div>
                <h3 style="font-size: 16px;"> التوقيع /</h3>
            </div>
        </div>
        <div style="width: 33%;">
            <div>
                <h3 style="font-size: 16px;">الكفيل الغارم</h3>
            </div>
            <div>
                <h3 style="font-size: 16px;"> الاســم / <span style="font-size: 16px;">${
                  placeholders.guarantors || ""
                }</span></h3>   
            </div>
            <div>
                <h3 style="font-size: 16px; color: red;"> التوقيع /</h3>
            </div>
        </div>
        <div style="width: 33%;">
            <div>
                <h3 style="font-size: 16px;">الشاهد الثاني</h3>
            </div>
            <div>
                <h3 style="font-size: 16px;"> الاســم / </h3>   
            </div>
            <div>
                <h3 style="font-size: 16px;"> التوقيع /</h3>
            </div>
        </div>
      </div>



        

      </main>`;

  const oathFormat =
    placeholders &&
    `
  <div style="width: 800px; margin: 0 auto">
  <main style="margin-top: 8rem;
  width: 100%;
  padding: 1rem;
  border: 1px solid black;
  border-radius: 80px;
  z-index: 1;">
  <div class="main">
      <div class="center">
          <button><h1 style="font-size: 16px;">ســــــــنــد لأمــــر </h1></button>
      </div>
  </div>

  <div class="content">
      <div class="content2">
         
          <div>
              <h3 style="font-size: 16px;">حرر هذا السند في <span>مدينة </span>    ${
                placeholders.customer_city || ""
              }        </h3>
          </div>
          <div>
              <h3 id="center" style="font-size: 16px;">بتاريخ: ${
                convertDateFormat(placeholders.date_contract, "ar-SA") || ""
              }</h3>
          </div>
         
          
      </div>
      <div class="content1">
          
          <div>
              <h3 style="color: red; font-size: 16px;">المبلـــــغ: ${
                placeholders.total_value + placeholders.legal_fees || ""
              }</h3>
          </div>
          <div>
              <h3 style="font-size: 16px;">ريال سعودي</h3>
          </div>
      </div>
  </div>

  <div >
      <h3 style="font-size: 16px;">أتعهد أن الموقع أدناه بأن أدفع بموجب هذا السند بدون قيد أو شرط لأمر </h3>
  </div>

  <div>
      <h3 style="font-size: 16px;">المبلغ الموضح أعلاه. </h3>
  </div>

  <div>
      <h3 style="font-size: 16px;">المشترى بالآجل والمستحق عند الإطلاع وأن يكون الاختصام المكاني للتنفيذ في مكان اصدار هذا السند</h3>
  </div>

  <div class="next-content">
      <div class="next-content1">
          <div style='width: 100%;'> <h3 style="font-size: 16px;">اسـم المدين : ${
            placeholders.customer_name || ""
          }</h3></div>
          <div style='width: 100%;'> <h3 style="font-size: 16px;">رقـم الهوية : ${
            placeholders.customer_id || ""
          }</h3></div>
      </div>
      <div class="next-content2">
          <div> <h3 style="font-size: 16px;">تاريخها : ${
            convertDateFormat(placeholders.customer_releasedate) || ""
          }</h3></div>
          <div><h3 style="font-size: 16px;"> مصدرها : ${
            placeholders.customer_source || ""
          }</h3></div>
      </div>
  </div>

  <div class="next-content">
      <div class="next-content1">
          <div> <h3 style="font-size: 16px;"> اسم الكفيل : ${
            placeholders.guarantors || ""
          }</h3></div>
          <div> <h3 style="color: black; font-size: 16px;">رقـم الهوية : ${
            placeholders.guarantor_Vid || ""
          }</h3></div>
      </div>
      <div class="next-content2">
          <div> <h3 style="color: black; font-size: 16px;">تاريخها : ${
            placeholders.guarantor_releaseDate !== null
              ? convertDateFormat(placeholders.guarantor_releaseDate)
              : ""
          }</h3></div>
          <div><h3 style="color: black; font-size: 16px;"> مصدرها : ${
            placeholders.guarantor_idSource || ""
          } </h3></div>
      </div>
  </div>

  <div class="last-content">
   <div><h3 style="font-size: 16px;">توقيع المدين</h3></div>
   <div><h3 style="font-size: 16px;">توقيع الكفيل</h3></div>
  </div>

</main>

<div class="bottom">
    <div class="bottom1"><h3 style="font-size: 16px;">
    هذا السند واجب الدفع بدون تعال بموجب قرار مجلس الوزراء الموقر رقم ٦٩٢ بتاريخ ١٣٨٣/٩/٢٦هـ والمتوج بالمرسوم الملكي الكريم رقم ۳۷ بتاريخ ۱۳۸۳/۱۰/۱۱هـ نظام الأوراق التجارية</h3></div>
    <div class="bottom2"><h3 style="font-size: 16px;">بموجب هذا السند يسقط المدين والكفيل الغارم حقوق التقديم والمطالبة والاحتجاج والاخطار بالامتناع عن الوفاء والمتعلقة بهذا السند كما يجوز للمدعي بموجب هذا السند الرجوع للمدين أو الكفيل الغارم منفردين أو مجتمعين ودون مراعاة أو ترتيب</h3></div>
   </div>

</div>
`;

  useEffect(() => {
    axios
      .get("https://api.amancredits.com/get-contract-info/" + customer_id)
      .then((res) => {
        // console.log(res.data.result[0])
        if (!res.data.result[0]) {
          setLoad(false);
        }
        setPlaceholders(res.data.result[0]);
      })
      .catch((err) => {
        console.error(err);
      });
    setOfficeName(
      JSON.parse(JSON.parse(sessionStorage.getItem("aman-admin")).admin)
        .branch_name
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>{lang.Customer_Management || "Customer Management"}</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <svg
                  className="sidebaricon"
                  xmlns="http://www.w3.org/2000/svg"
                  height="13"
                  width="15"
                  viewBox="0 0 576 512"
                >
                  <path d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z" />
                </svg>
              </li>
              <li className="breadcrumb-item">
                {lang.Customer_Management || "Customer Management"}
              </li>
              <li className="breadcrumb-item active">
                {lang.contract || "Contract"}
              </li>
            </ol>
          </nav>
        </div>

        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div>
                  <p style={{ color: "red" }}>
                    {!placeholders && "No Contracts found for this customer"}
                  </p>
                </div>
                <ContractForm
                  lang={lang}
                  load={load}
                  contractFormat={contractFormat}
                />
                <OathForm lang={lang} oathFormat={oathFormat} />
                <PolicyForm lang={lang} placeholders={placeholders} />
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default ContractInformation;
