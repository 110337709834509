import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

export default function PurchaseInventory({ lang }) {
  const [investorList, setInvestorList] = useState([]);
  const [inventoryType, setInventoryType] = useState([]);
  const [investor, setInvestor] = useState();
  const [account, setAccount] = useState();
  const [amount, setAmount] = useState();
  const [itemList, setItemList] = useState([]);
  const [itemType, setItemType] = useState();
  const [price, setPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState()
  const [defaultPrice, setDefaultPrice] = useState()
  const [itemName, setItemName] = useState();
  const [quantity, setQuantity] = useState(0);
  const [capital, setCapital] = useState(0);
  const [noInvError, setNoInvErr] = useState(false);

  const [branchId, setBranchId] = useState();

  const navigate = useNavigate();

  const handleSubmit = () => {
    const totalAmount = price * quantity;
    if (amount < totalAmount) {
      alert("Insufficiant balance");
      return;
    }
    try {
      axios
        .post("https://api.amancredits.com/add-item", {
          investor_id: investor,
          total_amount: totalAmount,
          item_type_id: itemType,
          item_id: itemName,
          item_price: price,
          item_total_price: totalPrice,
          item_default_price: defaultPrice,
          quantity: quantity,
          capital: capital,
          branch_id: branchId,
        })
        .then((res) => {
          console.log(res.data.Message);
          setAccount("");
          setInvestor("");
          setAmount("");
          setItemType("");
          setItemName("");
          setPrice("");
          setQuantity("");
          setCapital("");
          toast.success(res.data.Message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          navigate(-1);
        })
        .catch((err) => {
          console.error(err);
          toast.error("Error Occured", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    } catch (err) {
      console.error(err);
      toast.error("Error Occured", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handlePriceChange = (e) => {
    const newPrice = e.target.value;
    setPrice(newPrice);
    setCapital(newPrice * quantity); // Calculate capital based on new price
  };

  const handleQuantityChange = (e) => {
    const newQuantity = e;
    setQuantity(newQuantity);
    setCapital(price * newQuantity); // Calculate capital based on new quantity
  };

  useEffect(() => {
    if (branchId) {
      try {
        axios
          .get("https://api.amancredits.com/investor", {
            params: {
              branch_id: branchId,
            },
          })
          .then((res) => {
            setInvestorList(res.data.result);
          })
          .catch((err) => {
            console.error(err);
          });
      } catch (err) {
        console.error(err);
      }
      try {
        axios
          .get("https://api.amancredits.com/get-item-type", {
            params: {
              branch_id: branchId,
            },
          })
          .then((res) => {
            setInventoryType(res.data.result);
          })
          .catch((err) => {
            console.error(err);
          });
      } catch (err) {
        console.error(err);
      }
    }
  }, [branchId]);

  useEffect(() => {
    axios
      .get("https://api.amancredits.com/get-category-inventory", {
        params: {
          cateId: itemType,
          branch_id: branchId,
        },
      })
      .then((res) => {
        setItemList(res.data.result);
        if (res.data.result.length > 0) {
          setNoInvErr(false);
        } else {
          setNoInvErr(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [itemType]);

  useEffect(() => {
    setBranchId(
      JSON.parse(JSON.parse(sessionStorage.getItem("aman-admin")).admin).id
    );
  }, []);
  return (
    <>
      <main id="main" class="main">
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <div class="pagetitle">
          <h1>
            {lang.Investment_Loan_Management || "Investment/Loan Management"}
          </h1>
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                >
                  <path d="m21.743 12.331-9-10c-.379-.422-1.107-.422-1.486 0l-9 10a.998.998 0 0 0-.17 1.076c.16.361.518.593.913.593h2v7a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-4h4v4a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-7h2a.998.998 0 0 0 .743-1.669z"></path>
                </svg>
              </li>
              <li class="breadcrumb-item">
                {lang.Investment_Loan_Management ||
                  "Investment/Loan Management"}
              </li>
              <li class="breadcrumb-item active">
                {lang.Purchase_New_Item || "Purchase New Item"}
              </li>
            </ol>
          </nav>
        </div>

        <section class="section">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex mt-3 mb-3">
                    <h4 class="headingh4 fw-bold">
                      {lang.Purchase_New_Item || "Purchase New Item"}
                    </h4>
                  </div>

                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    <div class="d-flex mt-3 mb-3 justify-content-end">
                      <button type="submit" class="btn-save">
                        {lang.Save_And_Next || "Save & Next"}
                      </button>
                    </div>
                    <div class="row mb-3 d-flex justify-content-center">
                      <div class="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Investor || "Investor"}:
                          </span>
                          <select
                            class="form-select"
                            aria-label="Default select example"
                            value={investor}
                            required
                            onChange={(e) => {
                              setInvestor(e.target.value);
                              setAccount(
                                e.target.options[
                                  e.target.selectedIndex
                                ].getAttribute("data-account")
                              );
                              setAmount(
                                e.target.options[
                                  e.target.selectedIndex
                                ].getAttribute("data-ammount")
                              );
                            }}
                          >
                            <option selected="">
                              {lang.Investor || "Investor"}
                            </option>
                            {investorList.map((investor) => (
                              <option
                                key={investor.id}
                                value={investor.id}
                                data-account={investor.bank_account}
                                data-ammount={investor.balance}
                              >
                                {investor.FullName}
                              </option>
                            ))}
                          </select>
                        </label>
                      </div>
                    </div>

                    <div class="row mb-3 d-flex justify-content-center">
                      <div class="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Account || "Account"}:
                          </span>
                          <input
                            type="text"
                            class="form-control"
                            value={account}
                            disabled
                            required
                          />
                        </label>
                      </div>
                    </div>

                    <div class="row mb-3 d-flex justify-content-center">
                      <div class="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Balance || "Balance"}:
                          </span>
                          <input
                            type="text"
                            class="form-control"
                            value={amount}
                            disabled
                            required
                          />
                        </label>
                      </div>
                    </div>

                    <div class="row mb-3 d-flex justify-content-center">
                      <div class="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Comodity_Item || "Comodity Item"}:
                          </span>
                          <select
                            name=""
                            id=""
                            className="form-select"
                            onChange={(e) => setItemType(e.target.value)}
                            required
                          >
                            <option value="" selected disabled></option>
                            {inventoryType.map((inventory) => (
                              <option key={inventory.id} value={inventory.id}>
                                {inventory.type}
                              </option>
                            ))}
                          </select>
                        </label>
                        {noInvError && (
                          <p className="bg-danger text-white text-center">
                            {lang.no_item_found ||
                              "No inventory found for this category"}
                          </p>
                        )}
                      </div>
                    </div>

                    <div class="row mb-3 d-flex justify-content-center">
                      <div class="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Item || "Item"}:
                          </span>
                          <select
                            name=""
                            id=""
                            className="form-select"
                            onChange={(e) => setItemName(e.target.value)}
                            required
                          >
                            <option value="" selected disabled></option>
                            {itemList.map((item) => (
                              <option
                                key={item.id}
                                value={item.id}
                              >
                                {item.inventory_name}
                              </option>
                            ))}
                          </select>
                        </label>
                      </div>
                    </div>
                    <div class="row mb-3 d-flex justify-content-center">
                      <div class="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.total_default_price || "Total Default Price"}
                          </span>
                          <input
                            type="text"
                            class="form-control"
                            value={defaultPrice}
                            onChange={(e) => setDefaultPrice(e.target.value)}
                            required
                          />
                        </label>
                      </div>
                    </div>
                    <div class="row mb-3 d-flex justify-content-center">
                      <div class="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.total_purchase_price || "Total Purchase Price"}
                          </span>
                          <input
                            type="text"
                            class="form-control"
                            value={totalPrice}
                            onChange={(e) => setTotalPrice(e.target.value)}
                            required
                          />
                        </label>
                      </div>
                    </div>

                    <div class="row mb-3 d-flex justify-content-center">
                      <div class="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Quantity || "Quantity"}:
                          </span>
                          <input
                            type="text"
                            step="1"
                            class="form-control"
                            value={quantity}
                            onChange={(e) => {
                              // Check if the entered value is a positive integer
                              handleQuantityChange(parseInt(e.target.value) || 0)
                            }}
                            required
                          />
                        </label>
                      </div>
                    </div>

                    <div class="row mb-3 d-flex justify-content-center">
                      <div class="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Capital || "Capital"}:
                          </span>
                          <input
                            type="text"
                            class="form-control"
                            value={totalPrice}
                            disabled
                            required
                          />
                        </label>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
