import React, { Component } from "react";

export class InvestmentRep extends Component {
render() {
return (
<div>
<main id="main" class="main">
<div class="pagetitle">
    <h1>Investment/Loan Management</h1>
    <nav>
        <ol class="breadcrumb">
            <li class="breadcrumb-item">
                {" "}
                <img src="/img/view_details.png" alt="logo" class="sidebaricon" />
            </li>
            <li class="breadcrumb-item">Investment/Loan Management</li>
            <li class="breadcrumb-item active">Investor List</li>
        </ol>
    </nav>
</div>

<div class="card-body pt-3">
    <ul class="nav nav-tabs nav-tabs-bordered">
        <li class="nav-item">
            <button class="nav-link active">Investor List</button>
        </li>

        <li class="nav-item">
            <button
                class="nav-link"
                onclick="location.href='deposit-process.html'"
            >
                Deposit Process
            </button>
        </li>

        <li class="nav-item">
            <button
                class="nav-link"
                onclick="location.href='withdrawl-process.html'"
            >
                Withdrawl Process
            </button>
        </li>

        <li class="nav-item">
            <button
                class="nav-link"
                onclick="location.href='bank-transfer.html'"
            >
                Bank Transfer
            </button>
        </li>
    </ul>
</div>

<section class="section">
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div className="row">
                        <div className="col-9"></div>
                        <div className="col-3">
                            <div></div>
                            <button type="button" className="btn btn-primary">
                                <img src="/img/fi_plus.png" alt="" className="img-fluid me-2"></img>
                                Add New Customer
                            </button>
                        </div>
                    </div>

                    <div className="row pt-3 pb-3">
                        <div className="rr col-9">Investor List</div>

                        <div className="col-3">
                            <input
                                type="search"
                                className="form-control rounded"
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="search-addon"
                            />
                        </div>
                    </div>
                    <hr />

                    <table class="table datatable">
                        <thead>
                            <tr>
                                <th scope="col">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="defaultCheck1"
                                    />
                                    <span className="sp">Investor Name </span>
                                    <img src="/img/sort.png" alt="" className="img-fluid"></img>
                                </th>
                                <th scope="col">
                                    Category Loan{" "}
                                    <img src="/img/sort.png" alt="" className="img-fluid"></img>
                                </th>
                                <th scope="col">
                                    Mobile Number{" "}
                                    <img src="/img/sort.png" alt="" className="img-fluid"></img>
                                </th>
                                <th scope="col">
                                    Status{" "}
                                    <img src="/img/sort.png" alt="" className="img-fluid"></img>
                                </th>
                                <th scope="col" class="action">
                                    Action{" "}
                                    <img src="/img/sort.png" alt="" className="img-fluid"></img>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {" "}
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="defaultCheck1"
                                    />
                                    <span className="sp">Investor Name</span>
                                </td>
                                <td>Bussiness Loan</td>
                                <td>+9103245654</td>
                                <td>
                                    <button class="btn-process">In Process</button>
                                </td>
                                <td>
                                    <a
                                        className="icon show"
                                        href="/"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="true"
                                    >
                                        <img src="/img/view_details.png" alt="" className="sidebaricon" />
                                    </a>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    {" "}
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="defaultCheck1"
                                    />
                                    <span className="sp">Investor Name</span>
                                </td>
                                <td>Bussiness Loan</td>
                                <td>+9103245654</td>
                                <td>
                                    <button class="btn-process">In Process</button>
                                </td>
                                <td><a
                                    className="icon show"
                                    href="/"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="true"
                                >
                                    <img src="/img/view_details.png" alt="" className="sidebaricon" />
                                </a></td>
                            </tr>

                            <tr>
                                <td>
                                    {" "}
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="defaultCheck1"
                                    />
                                    <span className="sp">Investor Name</span>
                                </td>
                                <td>Bussiness Loan</td>
                                <td>+9103245654</td>
                                <td>
                                    <button class="btn-process">In Process</button>
                                </td>
                                <td><a
                                    className="icon show" href="/" data-bs-toggle="dropdown" aria-expanded="true"
                                >
                                    <img src="/img/view_details.png" alt="" className="sidebaricon" />
                                </a></td>
                            </tr>

                            <tr>
                                <td>
                                    {" "}
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="defaultCheck1"
                                    />
                                    <span className="sp">Investor Name</span>
                                </td>
                                <td>Bussiness Loan</td>
                                <td>+9103245654</td>
                                <td>
                                    <button class="btn-process">In Process</button>
                                </td>
                                <td><a
                                    className="icon show"
                                    href="/"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="true"
                                >
                                    <img src="/img/view_details.png" alt="" className="sidebaricon" />
                                </a></td>
                            </tr>

                            <tr>
                                <td>
                                    {" "}
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="defaultCheck1"
                                    />
                                    <span className="sp">Investor Name</span>
                                </td>
                                <td>Bussiness Loan</td>
                                <td>+9103245654</td>
                                <td>
                                    <button class="btn-process">In Process</button>
                                </td>
                                <td><a
                                    className="icon show"
                                    href="/"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="true"
                                >
                                    <img src="/img/view_details.png" alt="" className="sidebaricon" />
                                </a></td>
                            </tr>

                            <tr>
                                <td>
                                    {" "}
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="defaultCheck1"
                                    />
                                    <span className="sp">Investor Name</span>
                                </td>
                                <td>Bussiness Loan</td>
                                <td>+9103245654</td>
                                <td>
                                    <button class="btn-process">In Process</button>
                                </td>
                                <td><a
                                    className="icon show"
                                    href="/"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="true"
                                >
                                    <img src="/img/view_details.png" alt="" className="sidebaricon" />
                                </a></td>
                            </tr>

                            <tr>
                                <td>
                                    {" "}
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="defaultCheck1"
                                    />
                                    <span className="sp">Investor Name</span>
                                </td>
                                <td>Bussiness Loan</td>
                                <td>+9103245654</td>
                                <td>
                                    <button class="btn-process">In Process</button>
                                </td>
                                <td><a
                                    className="icon show"
                                    href="/"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="true"
                                >
                                    <img src="/img/view_details.png" alt="" className="sidebaricon" />
                                </a></td>
                            </tr>

                            <tr>
                                <td>
                                    {" "}
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="defaultCheck1"
                                    />
                                    <span className="sp">Investor Name</span>
                                </td>
                                <td>Bussiness Loan</td>
                                <td>+9103245654</td>
                                <td>
                                    <button class="btn-process">In Process</button>
                                </td>
                                <td><a
                                    className="icon show"
                                    href="/"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="true"
                                >
                                    <img src="/img/view_details.png" alt="" className="sidebaricon" />
                                </a></td>
                            </tr>

                            <tr>
                                <td>
                                    {" "}
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="defaultCheck1"
                                    />
                                    <span className="sp">Investor Name</span>
                                </td>
                                <td>Bussiness Loan</td>
                                <td>+9103245654</td>
                                <td>
                                    <button class="btn-process">In Process</button>
                                </td>
                                <td><a
                                    className="icon show"
                                    href="/"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="true"
                                >
                                    <img src="/img/view_details.png" alt="" className="sidebaricon" />
                                </a></td>
                            </tr>

                            <tr>
                                <td>
                                    {" "}
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="defaultCheck1"
                                    />
                                    <span className="sp">Investor Name</span>
                                </td>
                                <td>Bussiness Loan</td>
                                <td>+9103245654</td>
                                <td>
                                    <button class="btn-process">In Process</button>
                                </td>
                                <td><a
                                    className="icon show"
                                    href="/"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="true"
                                >
                                    <img src="/img/view_details.png" alt="" className="sidebaricon" />
                                </a></td>
                            </tr>

                            <tr>
                                <td>
                                    {" "}
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="defaultCheck1"
                                    />
                                    <span className="sp">Investor Name</span>
                                </td>
                                <td>Bussiness Loan</td>
                                <td>+9103245654</td>
                                <td>
                                    <button class="btn-process">In Process</button>
                                </td>
                                <td><a
                                    className="icon show"
                                    href="/"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="true"
                                >
                                    <img src="/img/view_details.png" alt="" className="sidebaricon" />
                                </a></td>
                            </tr>

                            <tr>
                                <td>
                                    {" "}
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="defaultCheck1"
                                    />
                                    <span className="sp">Investor Name</span>
                                </td>
                                <td>Bussiness Loan</td>
                                <td>+9103245654</td>
                                <td>
                                    <button class="btn-process">In Process</button>
                                </td>
                                <td><a
                                    className="icon show"
                                    href="/"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="true"
                                >
                                    <img src="/img/view_details.png" alt="" className="sidebaricon" />
                                </a></td>
                            </tr>

                            <tr>
                                <td>
                                    {" "}
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="defaultCheck1"
                                    />
                                    <span className="sp">Investor Name</span>
                                </td>
                                <td>Bussiness Loan</td>
                                <td>+9103245654</td>
                                <td>
                                    <button class="btn-process">In Process</button>
                                </td>
                                <td><a
                                    className="icon show"
                                    href="/"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="true"
                                >
                                    <img src="/img/view_details.png" alt="" className="sidebaricon" />
                                </a></td>
                            </tr>

                            <tr>
                                <td>
                                    {" "}
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="defaultCheck1"
                                    />
                                    <span className="sp">Investor Name</span>
                                </td>
                                <td>Bussiness Loan</td>
                                <td>+9103245654</td>
                                <td>
                                    <button class="btn-process">In Process</button>
                                </td>
                                <td><a
                                    className="icon show"
                                    href="/"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="true"
                                >
                                    <img src="/img/view_details.png" alt="" className="sidebaricon" />
                                </a></td>
                            </tr>

                            <tr>
                                <td>
                                    {" "}
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="defaultCheck1"
                                    />
                                    <span className="sp">Investor Name</span>
                                </td>
                                <td>Bussiness Loan</td>
                                <td>+9103245654</td>
                                <td>
                                    <button class="btn-process">In Process</button>
                                </td>
                                <td><a
                                    className="icon show"
                                    href="/"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="true"
                                >
                                    <img src="/img/view_details.png" alt="" className="sidebaricon" />
                                </a></td>
                            </tr>

                            <tr>
                                <td>
                                    {" "}
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="defaultCheck1"
                                    />
                                    <span className="sp">Investor Name</span>
                                </td>
                                <td>Bussiness Loan</td>
                                <td>+9103245654</td>
                                <td>
                                    <button class="btn-process">In Process</button>
                                </td>
                                <td><a
                                    className="icon show"
                                    href="/"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="true"
                                >
                                    <img src="/img/view_details.png" alt="" className="sidebaricon" />
                                </a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>
</main>
</div>
);
}
}

export default InvestmentRep;