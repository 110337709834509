import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import IndeterminateCheckbox from "../components/IndeterminateChechbox";
import Table from "../components/Table";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function InventoryCategory({ lang }) {
  const [inventoryList, setInventoryList] = useState([]);
  const [inventoryToDelete, setInventoryToDelete] = useState([]);
  const [InventoryCategory, setInventoryCategory] = useState([]);
  const [branchId, setBranchId] = useState();
  const [itemComodity, setItemComodity] = useState();

  const returnTable = async (table) => {
    await setInventoryToDelete(table.map((item) => item.original.id));
  };

  const handleComoditySubmit = (e) => {
    e.preventDefault();
    axios
      .post("https://api.amancredits.com/add-item-type", {
        item_type: itemComodity,
        branch_id: branchId,
      })
      .then((res) => {
        setItemComodity("");
        toast.success(res.data.Message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((err) => {
        console.error(err);
        toast.error("Error Occured", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const columns = [
    {
      header: lang.category || "Category",
      accessorKey: "type",
    },
    {
      header: lang.item_count || "Item count",
      accessorKey: "inventory_count",
    },
  ];

  useEffect(() => {
    if (branchId){
      axios
        .get("https://api.amancredits.com/get-item-type", {
          params: {
            branch_id: branchId,
          },
        })
        .then((res) => {
          setInventoryCategory(res.data.result);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [branchId]);

  useEffect(() => {
    setBranchId(
      JSON.parse(JSON.parse(sessionStorage.getItem("aman-admin")).admin).id
    );
  }, []);
  return (
    <>
      <main id="main" className="main">
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <div className="pagetitle">
          <h1>
            {lang.Investment_Loan_Management || "Investment/Loan Management"}
          </h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                >
                  <path d="m21.743 12.331-9-10c-.379-.422-1.107-.422-1.486 0l-9 10a.998.998 0 0 0-.17 1.076c.16.361.518.593.913.593h2v7a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-4h4v4a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-7h2a.998.998 0 0 0 .743-1.669z"></path>
                </svg>
              </li>
              <li className="breadcrumb-item ">
                {lang.Investment_Loan_Management ||
                  "Investment/Loan Management"}
              </li>
              <li className="breadcrumb-item active">
                {lang.Inventory_Category || "Inventory Category List"}
              </li>
            </ol>
          </nav>
        </div>

        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="row justify-content-end">
                  <div className="col-md-5 col-lg-3">
                    <button
                      type="button"
                      class="btn-save btn"
                      data-bs-toggle="modal"
                      data-bs-target="#Comodity"
                    >
                      {lang.Add_Category || "Add Category"}
                    </button>
                  </div>
                </div>

                <div className="row pt-3">
                  <div className="rr col-9">{lang.Inventory_Category || "Inventory Category List"}</div>
                </div>

                <div className="card-body">
                  <div classNames="d-flex mt-3 mb-3"></div>
                  <div classNames="d-flex mt-3 mb-3">
                    <Table
                      lang={lang}
                      data={InventoryCategory}
                      columns={columns}
                      returnTable={returnTable}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="modal fade" id="Comodity" tabindex="-1">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <form onSubmit={handleComoditySubmit}>
                  <div className="row mb-3 mt-5 d-flex justify-content-center">
                    <div className="col-sm-8  mb-3">
                      <label htmlFor="" className="w-100">
                        <span style={{ fontSize: "14px" }}>
                          {lang.add_item_category || "Add Item Category"}
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          value={itemComodity}
                          onChange={(e) => setItemComodity(e.target.value)}
                          required
                        />
                      </label>
                    </div>
                  </div>
                  <div className="text-center mb-5">
                    <button
                      type="button"
                      className="btn-save"
                      data-bs-dismiss="modal"
                    >
                      {lang.Cancel || "Cancel"}
                    </button>
                    <button type="submit" className="btn-cancel">
                      {lang.Add_Now || "Add Now"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
