import React, {useRef, useEffect} from 'react'

function IndeterminateCheckbox({indeterminate, ...rest}) {
    const ref= useRef(null);

    useEffect(() => {
        if (typeof indeterminate === "boolean"){
            ref.current.inindeterminate = !rest.checked && indeterminate;
        }
    }, [ref, indeterminate]);

  return (
    <input type="checkbox" ref={ref} {...rest} />
  )
}

export default IndeterminateCheckbox