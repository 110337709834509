import React from "react";
import IndeterminateCheckbox from "../components/IndeterminateChechbox";
import Table from "../components/Table";

export default function Loan({lang}) {
  const data = [
    {
      customerName: "CustomerName",
      occupation: "Occupation Here",
      mobileNumber: "Mobile Number",
    },
    {
      customerName: "CustomerName",
      occupation: "Occupation Here",
      mobileNumber: "Mobile Number",
    },
    {
      customerName: "CustomerName",
      occupation: "Occupation Here",
      mobileNumber: "Mobile Number",
    },
    {
      customerName: "CustomerName",
      occupation: "Occupation Here",
      mobileNumber: "Mobile Number",
    },
    {
      customerName: "CustomerName",
      occupation: "Occupation Here",
      mobileNumber: "Mobile Number",
    },
    {
      customerName: "CustomerName",
      occupation: "Occupation Here",
      mobileNumber: "Mobile Number",
    },
    {
      customerName: "CustomerName",
      occupation: "Occupation Here",
      mobileNumber: "Mobile Number",
    },
    {
      customerName: "CustomerName",
      occupation: "Occupation Here",
      mobileNumber: "Mobile Number",
    },
    {
      customerName: "CustomerName",
      occupation: "Occupation Here",
      mobileNumber: "Mobile Number",
    },
    {
      customerName: "CustomerName",
      occupation: "Occupation Here",
      mobileNumber: "Mobile Number",
    },
    {
      customerName: "CustomerName",
      occupation: "Occupation Here",
      mobileNumber: "Mobile Number",
    },
    {
      customerName: "CustomerName",
      occupation: "Occupation Here",
      mobileNumber: "Mobile Number",
    },
    {
      customerName: "CustomerName",
      occupation: "Occupation Here",
      mobileNumber: "Mobile Number",
    },
    {
      customerName: "CustomerName",
      occupation: "Occupation Here",
      mobileNumber: "Mobile Number",
    },
    {
      customerName: "CustomerName",
      occupation: "Occupation Here",
      mobileNumber: "Mobile Number",
    },
    {
      customerName: "CustomerName",
      occupation: "Occupation Here",
      mobileNumber: "Mobile Number",
    },
  ];
  const columns = [
    {
      id: "select",
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
    },
    {
      header: "Customer Name",
      accessorKey: "customerName",
    },
    {
      header: "Occupation",
      accessorKey: "occupation",
    },
    {
      header: "Mobile Number",
      accessorKey: "mobileNumber",
    },
  ];

  return (
    <>
      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Reports</h1>
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                
                <img src="/img/homeicon.png" alt="logo" class="sidebaricon" />
              </li>
              <li class="breadcrumb-item">Reports</li>
              <li class="breadcrumb-item active">loan</li>
            </ol>
          </nav>
        </div>

        <section class="section">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex mt-3 mb-3">
                    <h4 class="rr">Loan</h4>
                    <button class="btn btn-export">
                      {/* Export <img src="/img/export.png" alt="export" /> */}
                    </button>
                  </div>

                  <div classNames="d-flex mt-3 mb-3">
                    <Table lang={lang} data={data} columns={columns} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
