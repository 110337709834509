import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

export default function AddLoanRequest({ lang }) {
  const [customerList, setCustomerList] = useState([]);
  const [loanList, setLoanList] = useState([]);
  const [customer, setCustomer] = useState();
  const [loanType, setLoanType] = useState();
  const [title, setTitle] = useState();
  const [amount, setAmount] = useState();
  const [additional, setAdditional] = useState();
  const [branchId, setBranchId] = useState();

  const navigate = useNavigate();

  const handleSubmit = () => {
    try {
      axios
        .post("https://api.amancredits.com/add-loan-request", {
          customer_id: customer,
          request_type_id: loanType,
          title: title,
          description: additional,
          required_amount: amount,
          branch_id: branchId,
        })
        .then((res) => {
          navigate("/dashboard/loan-avail-list");
        })
        .catch((err) => {
          console.error(err);
          toast.error("Error Occured", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    } catch (err) {
      console.error(err);
      toast.error("Error Occured", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  useEffect(() => {
    try {
      axios
        .get("https://api.amancredits.com/get-user/customer", {
          params: {
            branch_id: branchId,
          },
        })
        .then((res) => {
          setCustomerList(res.data.result);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }
    try {
      axios
        .get("https://api.amancredits.com/get-loan-types", {
          params: {
            branch_id: branchId,
          },
        })
        .then((res) => {
          setLoanList(res.data.result);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }
  }, [branchId]);
  useEffect(() => {
    setBranchId(
      JSON.parse(JSON.parse(sessionStorage.getItem("aman-admin")).admin).id
    );
  }, []);
  return (
    <>
      <main id="main" class="main">
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <div class="pagetitle">
          <h1>
            {lang.Investment_Loan_Management || "Investment/Loan Management"}
          </h1>
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                >
                  <path d="m21.743 12.331-9-10c-.379-.422-1.107-.422-1.486 0l-9 10a.998.998 0 0 0-.17 1.076c.16.361.518.593.913.593h2v7a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-4h4v4a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-7h2a.998.998 0 0 0 .743-1.669z"></path>
                </svg>
              </li>
              <li class="breadcrumb-item">
                {lang.Investment_Loan_Management ||
                  "Investment/Loan Management"}
              </li>
              <li class="breadcrumb-item active">
                {lang.Add_New_Loan_Request || "Add New Loan Request"}
              </li>
            </ol>
          </nav>
        </div>

        <section class="section">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex mt-3 mb-3">
                    <h4 class="headingh4 fw-bold">
                      {lang.Loan_Request_Form || "Loan Request Form"}
                    </h4>
                  </div>
                  <form onSubmit={e => {
                    e.preventDefault()
                    handleSubmit()
                    }}>
                    <div className="d-flex mt-3 mb-3 justify-content-end">
                      <button type="button" className="btn-save">
                        {lang.Cancel || "Cancel"}
                      </button>
                      <button type="submit" className="btn-cancel">
                        {lang.Save_And_Next || "Save & Next"}
                      </button>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-sm-4">
                        <label htmlFor="customer" className="w-100">
                          <span style={{ fontSize: "14px" }}>{lang.Customer || "Customer"}:</span>
                          <select
                            id="customer"
                            className="form-select"
                            aria-label="Default select example"
                            value={customer}
                            required
                            onChange={(e) => {
                              setCustomer(e.target.value);
                              console.log(e.target.value);
                            }}
                          >
                            <option selected="">{lang.Customer || "Customer"}</option>
                            {customerList.map((customer) => (
                              <option key={customer.id} value={customer.id}>
                                {customer.FullName}
                              </option>
                            ))}
                          </select>
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-sm-4">
                        <label htmlFor="loanType" className="w-100">
                          <span style={{ fontSize: "14px" }}>{lang.Loan_Type || "Loan Type"}:</span>
                          <select
                            id="loanType"
                            className="form-select"
                            aria-label="Default select example"
                            value={loanType}
                            onChange={(e) => setLoanType(e.target.value)}
                          >
                            <option selected="">{lang.Loan_Type || "Loan Type"}</option>
                            {loanList.map((loan) => (
                              <option
                                key={loan.request_type_id}
                                value={loan.request_type_id}
                              >
                                {loan.request_type}
                              </option>
                            ))}
                          </select>
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-sm-4">
                        <label htmlFor="title" className="w-100">
                          <span style={{ fontSize: "14px" }}>{lang.Title || "Title"}:</span>
                          <input
                            type="text"
                            id="title"
                            className="form-control"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                          />
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-sm-4">
                        <label htmlFor="amount" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Required_Amount || "Required Amount"}:
                          </span>
                          <input
                            type="text"
                            id="amount"
                            className="form-control"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            required
                          />
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-sm-4">
                        <label htmlFor="additional" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Additional_Notes ||"Additional Notes"}:
                          </span>
                          <textarea
                            type="text"
                            id="additional"
                            className="form-control"
                            value={additional}
                            onChange={(e) => setAdditional(e.target.value)}
                            rows={5}
                            required
                          />
                        </label>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
