import React from 'react'
import IndeterminateCheckbox from '../components/IndeterminateChechbox';
import Table from "../components/Table"
import { Link } from 'react-router-dom';

export default function ListOfDel() {
    const data = [
        {
          customerName: "CustomerName",
          occupation: "Occupation Here",
          mobileNumber: "Mobile Number",
        },
        {
          customerName: "CustomerName",
          occupation: "Occupation Here",
          mobileNumber: "Mobile Number",
        },
        {
          customerName: "CustomerName",
          occupation: "Occupation Here",
          mobileNumber: "Mobile Number",
        },
        {
          customerName: "CustomerName",
          occupation: "Occupation Here",
          mobileNumber: "Mobile Number",
        },
        {
          customerName: "CustomerName",
          occupation: "Occupation Here",
          mobileNumber: "Mobile Number",
        },
        {
          customerName: "CustomerName",
          occupation: "Occupation Here",
          mobileNumber: "Mobile Number",
        },
        {
          customerName: "CustomerName",
          occupation: "Occupation Here",
          mobileNumber: "Mobile Number",
        },
        {
          customerName: "CustomerName",
          occupation: "Occupation Here",
          mobileNumber: "Mobile Number",
        },
        {
          customerName: "CustomerName",
          occupation: "Occupation Here",
          mobileNumber: "Mobile Number",
        },
        {
          customerName: "CustomerName",
          occupation: "Occupation Here",
          mobileNumber: "Mobile Number",
        },
        {
          customerName: "CustomerName",
          occupation: "Occupation Here",
          mobileNumber: "Mobile Number",
        },
        {
          customerName: "CustomerName",
          occupation: "Occupation Here",
          mobileNumber: "Mobile Number",
        },
        {
          customerName: "CustomerName",
          occupation: "Occupation Here",
          mobileNumber: "Mobile Number",
        },
        {
          customerName: "CustomerName",
          occupation: "Occupation Here",
          mobileNumber: "Mobile Number",
        },
        {
          customerName: "CustomerName",
          occupation: "Occupation Here",
          mobileNumber: "Mobile Number",
        },
        {
          customerName: "CustomerName",
          occupation: "Occupation Here",
          mobileNumber: "Mobile Number",
        },
      ]
      const columns = [
        {
          id: "select",
          header: ({ table }) => (
            <IndeterminateCheckbox
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
              }}
            />
          ),
          cell: ({ row }) => (
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          ),
        },
        {
          header: "Customer Name",
          accessorKey: "customerName",
        },
        {
          header: "Occupation",
          accessorKey: "occupation",
        },
        {
          header: "Mobile Number",
          accessorKey: "mobileNumber"
        },
      ];
      return (
        <div>
           <main id="main" className="main">
              <div className="pagetitle">
                <h1>General Management</h1>
                <nav>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      {" "}
                      <img src="img/homeicon.png" alt="logo" className="sidebaricon" />
                    </li>
                    <li className="breadcrumb-item">Contract</li>
                    <li className="breadcrumb-item active">Delinquent Payment</li>
                  </ol>
                </nav>
              </div>
    
    
              <section className="section">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-9"></div>
                          <div className="col-3">
                            <div></div>
                            <Link to="/addnewdel"> 
                            <button type="button" className="btn btn-primary d-flex justify-content-end ms-2">
                              <img src="img/fi_plus.png" alt="" className="img-fluid me-2" /> Add New Payment
                            </button>
                            </Link>
                          </div>
                        </div>
    
                        <div className="row pt-3 pb-3">
                          <div className="rr col-9">List Of Delinquent Payment</div>
                        </div> 
                        <div classNames="d-flex mt-3 mb-3">
                         <Table data={data} columns={columns} />
                        </div> 
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </main>
        </div>
  )
}
