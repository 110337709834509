import React from 'react'
import { Link } from 'react-router-dom'

export default function InvesTransfer() {
  return (
    <div>
      <main id="main" className="main">

<div className="pagetitle">
  <h1>Investment/Loan Management</h1>
  <nav>
    <ol className="breadcrumb">
      <li className="breadcrumb-item"> <img src="img/homeicon.png" alt="logo" className="sidebaricon"/></li>
      <li className="breadcrumb-item">Investment/Loan Management</li>
       <li className="breadcrumb-item active">Conversion Process</li>
      
    </ol>
  </nav>
</div>
<div className="card-body pt-3">
              <ul className="nav nav-tabs nav-tabs-bordered">

            <li className="nav-item">
            <Link to ="/investor-report">
                <button className="nav-link"> Investor List </button>
                </Link>
            </li>

            <li className="nav-item">
            <Link to ="/investment-deposite">
                <button className="nav-link "> Deposit Process </button>
            </Link>
            </li>

            <li className="nav-item">
            <Link to ="/withdraw">
                <button className="nav-link"> Withdrawl Process </button>
                </Link>
            </li>

            <li className="nav-item">
            <Link to ="/bank-transfer">
                <button className="nav-link active"> Bank Transfer </button>
     
                </Link>
            </li>
             

          </ul>
             </div>
<section className="section">
  <div className="row">
    <div className="col-lg-12">

      <div className="card">
        <div className="card-body">
             
            <div className="d-flex mt-3 mb-3">
                <h4 className="headingh4 fw-bold">Conversion Process</h4>
               
            </div>
            
            <div className="d-flex mt-3 mb-3 justify-content-end">
                <button type="submit" className="btn-save">Cancel </button>
                <button type="submit" className="btn-cancel">Save & Publish </button>
            </div>
            
            
            <form>
                  <div className="row mb-3 d-flex justify-content-center">
             
              <div className="col-sm-4">
                <select className="form-select" aria-label="Default select example">
                  <option selected="">Investor</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
             
            </div>
                
                
            <div className="row mb-3 d-flex justify-content-center">
             
              <div className="col-sm-4">
                <input type="text" className="form-control" placeholder="Account"/>
              </div>
               
             
            </div>
            
            <div className="row mb-3 d-flex justify-content-center">
             
              <div className="col-sm-4">
                <input type="text" className="form-control" placeholder="Amount"/>
              </div>
              
              
             
            </div>
            
            <div className="row mb-3 d-flex justify-content-center">
             
              <div className="col-sm-4">
                <input type="text" className="form-control" placeholder="Balance Before"/>
              </div>
              
            
              
             
            </div>
            
             <div className="row mb-3 d-flex justify-content-center">
             
               <div className="col-sm-4">
                <input type="text" className="form-control" placeholder="Balance After"/>
              </div>
              
             
            </div>
            
              <div className="row mb-3 d-flex justify-content-center">
             
               <div className="col-sm-4">
                <textarea className="form-control" placeholder="Statement" rows="5"></textarea>
              </div>
              
             
             
            </div>
            
        

          </form>

        
         

        </div>
      </div>

    </div>
  </div>
</section>

</main>
    </div>
  )
}

