import React from "react";
import SideNav from "../components/SideNav";
import AddCusgarantor from "../pages/AddCusgarantor";
import AddCustomer from "../pages/AddCustomer";
import AddLoanList from "../pages/AddLoanList";
import AddNewCommision from "../pages/AddNewCommision";
import AddNewDealer from "../pages/AddNewDealer";
import AddNewDel from "../pages/AddNewDel";
import AddNewInstall from "../pages/AddNewInstall";
import AddNewPool from "../pages/AddNewPool";
import AddNewProfit from "../pages/AddNewProfit";
import AddNewValid from "../pages/AddNewValid";
import Addinvestor from "../pages/Addinvestor";
import BasicInfo from "../pages/BasicInfo";
import CollectionAndPayment from "../pages/Collection&Payment";
import ContractInfo from "../pages/ContractInfo";
import Contracts from "../pages/Contracts";
import CusManGuarantor from "../pages/CusManGuarantor";
import Customer from "../pages/Customer";
import Dashboard from "../pages/Dashboard";
import Dealer from "../pages/Dealer";
import DealerList from "../pages/DealerList";
import DealerReport from "../pages/DealerReport";
import Identification from "../pages/Identification";
import InstallmentAndPayment from "../pages/InstallmentAndPayment";
import InstallmentPayment from "../pages/InstallmentPayment";
import InventoryLoanList from "../pages/InventoryLoanList";
import InvesDeposit from "../pages/InvesDeposit";
import InvesTransfer from "../pages/InvesTransfer";
import InvestReport from "../pages/InvestReport";
import InvestmentPool from "../pages/InvestmentPool";
import InvestmentRep from "../pages/InvestmentRep";
import Investment from "../pages/InvestorRep";
import InvestorRep from "../pages/InvestorRep";
import InvestorReport from "../pages/InvestorReport";
import Item from "../pages/Item";
import ItemReport from "../pages/ItemReport";
import ListOfContract from "../pages/ListOfContract";
import ListOfContractComision from "../pages/ListOfContractComision";
import ListOfDel from "../pages/ListOfDel";
import Loan from "../pages/Loan";
import LoanAvailList from "../pages/LoanAvailList";
import AddLoanRequest from "../pages/AddLoanRequest";
import LoanReport from "../pages/LoanReport";
import Profie from "../pages/Profie";
import PurchaseInventory from "../pages/PurchaseInventory";
import Report from "../pages/Report";
import Returns from "../pages/Returns";
import TypeOfLoans from "../pages/TypeOfLoan";
import UserProfile from "../pages/UserProfile";
import UserReport from "../pages/UserReports";
import ValidCon from "../pages/ValidCon";
import WithDraw from "../pages/WithDraw";
import Zakat from "../pages/Zakat";
import Accounts from "../pages/Accounts";
import Expenses from "../pages/Expenses";
import AdvanceOptions from "../pages/AdvanceOptions";
import PoolReports from "../pages/PoolReports";
import LoanContract from "../pages/LoanContract";
import FutureContractInfo from "../pages/FutureContractInfo";
import ContractInformation from "../pages/ContractInformation";
import { Route, Routes } from "react-router-dom";
import InventoryCategory from "../pages/InventoryCategory";
import PurchaseInventoryList from "../pages/PurchaseInventoryList";
import SystemInventory from "../pages/SystemInventory";
import ContractComplete from "../pages/ContractComplete";
import PurchaseReport from "../pages/PurchaseReport";
import InvestorCustomerReport from "../pages/InvestorCustomerReport";
import DealerChangePassword from "../pages/DealerChangePassword";
import CompletedContracts from "../pages/CompletedContracts";

function AdminLayout({ language, dir, currentLangFunc, currentDir }) {
  // console.log(language)
  return (
    <div className="mx-auto" dir={`${dir}`}>
      <SideNav
        lang={language}
        dirFormat={dir}
        currentLangFunc={currentLangFunc}
        currentDir={currentDir}
      />
      <main className={`main ${dir === "rtl" ? "main-right" : "main-left"}`}>
        <Routes>
          <Route
            exact
            path="/profile"
            element={<Profie lang={language} dirFormat={dir} />}
          />
          <Route
            path="/"
            element={<Dashboard lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/customer"
            element={<Customer lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/customer/add-customer"
            element={<AddCustomer lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/customer-manager"
            element={<CusManGuarantor lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/customer-manager/add-guarantor"
            element={<AddCusgarantor lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/investor-report"
            element={<InvestorRep lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/inventory-category"
            element={<InventoryCategory lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/inventory-system"
            element={<SystemInventory lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/inventory"
            element={<PurchaseInventoryList lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/inventory/inventory-purchase"
            element={<PurchaseInventory lang={language} dirFormat={dir} />}
          />
          <Route exact path="/investment-report" element={<InvestmentRep />} />
          <Route exact path="/investment" element={<Investment />} />
          <Route
            exact
            path="/investment-pool"
            element={<InvestmentPool lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/investment-pool/add-new-pool"
            element={<AddNewPool lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/loan-avail-list"
            element={<LoanAvailList lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/loan-avail-list/add-new-loan"
            element={<AddLoanRequest lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/collection-and-pay"
            element={<CollectionAndPayment lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/installment-and-pay"
            element={<InstallmentAndPayment lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/inventory-loan"
            element={<InventoryLoanList lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/return"
            element={<Returns lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/type-of-loan"
            element={<TypeOfLoans lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/type-of-loan/add-loan-list"
            element={<AddLoanList lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/contracts"
            element={<Contracts lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/customer/contract-information/:customer_id"
            element={<ContractInformation lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/contracts/valid-contract"
            element={<ValidCon lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/contracts/valid-contract/complete-contract/:contract_id"
            element={<ContractComplete lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/contracts/valid-contract/add-new-valid"
            element={<AddNewValid lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/report/list-of-contract"
            element={
              <ListOfContract
                path={language.Profit_Contract || "Profit Contracts"}
                heading={
                  language.List_of_Profit_Contract || "List Of Profit Contract"
                }
                lang={language}
                dirFormat={dir}
              />
            }
          />
          <Route
            exact
            path="/report/defaulted-contracts"
            element={
              <ListOfContract
                path={language.Defaulted_Contracts || "Defaulted Contracts"}
                heading={language.Defaulted_Contracts || "Defaulted Contracts"}
                lang={language}
                dirFormat={dir}
                status={3}
              />
            }
          />
          <Route
            exact
            path="/report/contract-profit"
            element={
              <CompletedContracts
                path={language.complete_contract || "Complete Contract"}
                heading={language.complete_contract || "Complete Contract"}
                lang={language}
                dirFormat={dir}
                status={2}
              />
            }
          />
          <Route
            exact
            path="/report/pure-contracts"
            element={
              <ListOfContract
                path={language.Pure_Contracts || "Pure Contracts"}
                heading={language.Pure_Contracts || "Pure Contracts"}
                lang={language}
                dirFormat={dir}
                type={1}
              />
            }
          />
          <Route
            exact
            path="/report/installment-payments"
            element={
              <ListOfContract
                path={
                  language.Future + " " + language.Contracts ||
                  "Future Contracts"
                }
                heading={
                  language.Future + " " + language.Contracts ||
                  "Future Contracts"
                }
                lang={language}
                dirFormat={dir}
                type={2}
              />
            }
          />
          <Route
            exact
            path="/add-new-profit"
            element={<AddNewProfit lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/list-of-del"
            element={<ListOfDel lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/add-new-del"
            element={<AddNewDel lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/intsallment-payment"
            element={<InstallmentPayment />}
          />
          <Route
            exact
            path="/add-new-installment"
            element={<AddNewInstall />}
          />
          <Route
            exact
            path="/list-commision"
            element={<ListOfContractComision />}
          />
          <Route exact path="/add-commision" element={<AddNewCommision />} />
          <Route
            exact
            path="/contracts/basic-information"
            element={<BasicInfo lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/contracts/future/basic-information"
            element={<BasicInfo lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/contracts/identification"
            element={<Identification lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/contracts/future/identification"
            element={<Identification lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/contracts/contract-information"
            element={<ContractInfo lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/contracts/future/contract-information"
            element={<FutureContractInfo lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/loan"
            element={<Loan lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/item"
            element={<Item lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/dealer"
            element={<Dealer lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/withdraw"
            element={<WithDraw lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/report"
            element={<Report lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/report/user-report"
            element={<UserReport lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/report/investor-report-list"
            element={<InvestorReport lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/report/investor-user-report"
            element={<InvestorCustomerReport lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/report/purchase-report"
            element={<PurchaseReport lang={language} />}
          />
          <Route
            exact
            path="/report/invest-report-list"
            element={<InvestReport lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/report/loan-report-list"
            element={<LoanReport lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/report/item-report-list"
            element={<ItemReport lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/report/dealer-report-list"
            element={<DealerReport lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/investor-report/add-investor"
            element={<Addinvestor lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/investment-deposite"
            element={<InvesDeposit lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/bank-transfer"
            element={<InvesTransfer lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/dealer-list"
            element={<DealerList lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/dealer-list/:dealer_id"
            element={<DealerChangePassword lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/dealer-list/add-new-dealer"
            element={<AddNewDealer lang={language} dirFormat={dir} />}
          />
          <Route exact path="/zakat" element={<Zakat />} />
          <Route
            exact
            path="/customer/profile/:user_type/:id"
            element={<UserProfile lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="*"
            element={<Dashboard lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/accounts"
            element={<Accounts lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/expenses"
            element={<Expenses lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/advance-options"
            element={<AdvanceOptions lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/report/pool-report"
            element={<PoolReports lang={language} dirFormat={dir} />}
          />
          <Route
            exact
            path="/contracts/valid-contract/add-loan-contract"
            element={<LoanContract lang={language} dirFormat={dir} />}
          />
        </Routes>
      </main>
    </div>
  );
}

export default AdminLayout;
