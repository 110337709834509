import React, { useEffect, useMemo, useState } from "react";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getPaginationRowModel,
  getFilteredRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";

function Table(props) {
  const [filtering, setFiltering] = useState("");
  const [rowSelection, setRowSelection] = useState({});
  const [sorting, setSorting] = useState([]);
  const [tableData, setTableData] = useState(props.data);
  const [deleteOption] = useState(props.deleteButton);
  const lang = props.lang;

  const data = useMemo(() => tableData, [tableData]);

  const columns = props.columns;

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      globalFilter: filtering,
      rowSelection: rowSelection,
      sorting: sorting,
    },
    onRowSelectionChange: setRowSelection,
    enableRowSelection: true,
    onSortingChange: setSorting,
    onGlobalFilterChange: setFiltering,
  });

  useEffect(() => {
    setTableData(props.data);
  }, [props.data]);

  return (
    <div>
      <div className="card-body row gx-2 justify-content-end align-items-center">
        <div className="col-6 col-lg-3">
          <input
            type="search"
            className="rounded mb-4 border p-1 w-100"
            placeholder={`${lang.Search || "Search"}`}
            value={filtering}
            onChange={(e) => setFiltering(e.target.value)}
          />
        </div>
        {deleteOption && (
          <div className="col-6 col-lg-2">
            <button
              className="btn btn-danger w-100 mb-4"
              onClick={(e) => {
                e.preventDefault();
                props.returnTable(table.getSelectedRowModel().flatRows);
              }}
            >
              {lang.Delete_Text || "Delete"}
            </button>
          </div>
        )}
      </div>
      <div class="table-responsive">
        <table className="w-100 table">
          <thead className="">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    scope="col"
                    className=""
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <span className="">
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {
                        {
                          asc: (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="#374151"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                            >
                              <path d="M7 11h10v2H7zM4 7h16v2H4zm6 8h4v2h-4z"></path>
                            </svg>
                          ),
                          desc: (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ transform: "rotate(180deg)" }}
                              className=""
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                            >
                              <path d="M7 11h10v2H7zM4 7h16v2H4zm6 8h4v2h-4z"></path>
                            </svg>
                          ),
                        }[header.column.getIsSorted() ?? null]
                      }
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {data && (
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td className="" key={cell.id}>
                      <span className="">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </span>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
      {props.totalContractValue &&
        props.totalContractValue.totalInvestorAmount && (
          <div>
            <div
              className="d-md-flex justify-content-end pe-5 gap-5 me-5 mt-5 pt-2"
              style={{ borderTop: "1px solid #6E7079" }}
            >
              <p className="fw-bold me-5">Total Contract Value: </p>
              <p className="pe-4">
                {props.totalContractValue.totalInvestorAmount}
              </p>
            </div>
            <div className="d-md-flex justify-content-end pe-5 gap-5 me-5 pt-2">
              <p className="fw-bold me-5">Total Paid Value: </p>
              <p className="pe-4">{props.totalContractValue.totalPaid}</p>
            </div>
            <div className="d-md-flex justify-content-end pe-5 gap-5 me-5 pt-2">
              <p className="fw-bold me-5">Total Profit Value: </p>
              <p className="pe-4">{props.totalContractValue.totalProfit}</p>
            </div>
          </div>
        )}
      <div className="d-flex flex-column flex-md-row mt-5 w-100 justify-content-between">
        <div className="">
          <span>
            <select
              className="btn"
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </span>
          <span className="ps-2">
            {lang.items_per_page || "items per page"}
          </span>
          <span>
            1-{table.getRowModel().rows.length} {lang.of || "of"} {data.length}{" "}
            {lang.Items || "items"}
          </span>
        </div>
        <div className="">
          <span>
            <input
              className="next-page text-center"
              style={{ width: "2.5rem" }}
              type="text"
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                table.setPageIndex(page);
              }}
            />
          </span>
          <span className="px-2 ">
            {" "}
            {lang.of || "of"} {table.getPageCount()} {lang.pages || "pages"}
          </span>
          <span className="">
            <button
              className="btn-01 border"
              disabled={!table.getCanPreviousPage()}
              onClick={() => table.previousPage()}
            >
              <svg
                className=""
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z"></path>
              </svg>
            </button>
            <button
              className="btn-01 border"
              disabled={!table.getCanNextPage()}
              onClick={() => table.nextPage()}
            >
              <svg
                className=""
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"></path>
              </svg>
            </button>
          </span>
        </div>
      </div>
    </div>
  );
}

export default Table;
