import "./App.css";
import LoginPage from "./pages/LoginPage";
import {
  Route,
  BrowserRouter,
  Routes,
} from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect } from "react";
import AdminLayout from "./layouts/AdminLayout";

// let token = true;

// const PrivateRouter = (token, Component, redirectPath) => {
//   return !token ? redirectPath : <Component />;
// };

export const App = () => {
  const [language, setLanguage] = useState({});
  const [currentLang, setCurrentLang] = useState("ar");
  const [dir, setDir] = useState("ltr");

  const currentDir = (dir) => {
    setDir(dir);
  };

  const currentLangFunc = (lang) => {
    // console.log(lang)
    setCurrentLang(lang);
  };

  useEffect(() => {
    axios
      .get("https://api.amancredits.com/get-lang?lang=" + currentLang)
      .then((res) => {
        setLanguage(res.data.result[0]);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [currentLang]);

  // useEffect(() => {
  //   console.log(currentLang)
  // }, [currentLang])

  return (
    <>
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/dashboard/*" element={<AdminLayout language={language} dir={dir} currentDir={currentDir} currentLangFunc={currentLangFunc} />} />
    </Routes>
    </BrowserRouter>
    </>
  )



  
};
export default App;
