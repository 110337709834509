import axios from "axios";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function AddNewPool({ lang }) {
  const [investorList, setInvestorList] = useState([]);
  const [groupName, setGroupName] = useState();
  const [investors, setInvestors] = useState();
  const [total, setTotal] = useState();
  const [branchId, setBranchId] = useState();

  const handleSubmit = () => {
    try {
      axios
        .post("https://api.amancredits.com/create-pool", {
          title: groupName,
          total_value: total,
          investors: investors,
          branch_id: branchId,
        })
        .then((res) => {
          setGroupName("");
          setInvestors([]);
          setTotal("");
          toast.success(res.data.Message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        })
        .catch((err) => {
          console.error(err);
          toast.error("Error Occured", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    } catch (err) {
      console.error(err);
      toast.error("Error Occured", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  useEffect(() => {
    try {
      axios
        .get("https://api.amancredits.com/get-investor-pool", {
          params: {
            branch_id: branchId,
          },
        })
        .then((res) => {
          const mappedOptions = res.data.result.map((investor) => ({
            value: investor.id,
            label: investor.FullName,
          }));
          setInvestorList(mappedOptions);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }
  }, [branchId]);

  useEffect(() => {
    setBranchId(
      JSON.parse(JSON.parse(sessionStorage.getItem("aman-admin")).admin).id
    );
  }, [])

  return (
    <>
      <main id="main" className="main">
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <div className="pagetitle">
          <h1>
            {lang.Investment_Loan_Management || "Investment/Loan Management"}
          </h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                >
                  <path d="m21.743 12.331-9-10c-.379-.422-1.107-.422-1.486 0l-9 10a.998.998 0 0 0-.17 1.076c.16.361.518.593.913.593h2v7a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-4h4v4a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-7h2a.998.998 0 0 0 .743-1.669z"></path>
                </svg>
              </li>
              <li className="breadcrumb-item">
                {lang.Investment_Loan_Management ||
                  "Investment/Loan Management"}
              </li>
              <li className="breadcrumb-item ">
                {lang.Investment_List || "Investment List"}
              </li>
              <li className="breadcrumb-item active">
                {lang.Add_New_Pool || "Add New Pool"}
              </li>
            </ol>
          </nav>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex mt-3 mb-3">
                    <h4 className="headingh4 fw-bold">
                      {lang.Add_New_Group || "Add New Group"}
                    </h4>
                  </div>

                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    <div className="d-flex mt-3 mb-3 justify-content-end">
                      <button className="btn-save">
                        {lang.Save_as_Draft || "Save as Draft"}
                      </button>
                      <button type="submit" className="btn-cancel ms-4">
                        {lang.Save_And_Publish || "Save & Publish"}
                      </button>
                    </div>
                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-4">
                        <label htmlFor="" className="w-100"></label>
                        <span style={{ fontSize: "14px" }}>
                          {lang.Group_Name || "Group Name"}:
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setGroupName(e.target.value)}
                          value={groupName}
                          required
                        />
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center ">
                      <div className="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Add_Investor || "Add Investor"}:
                          </span>
                          <Select
                            className=""
                            isMulti
                            options={investorList}
                            placeholder={"Add Investor"}
                            onChange={(e) => setInvestors(e)}
                            value={investors}
                            required
                          />
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center ">
                      <div className="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Total_Value || "Total Value"}:
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Total Value"
                            onChange={(e) => setTotal(e.target.value)}
                            value={total}
                            required
                          />
                        </label>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
