import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import DatePicker from "react-date-picker";
import DatePicker from "react-multi-date-picker";
import arabic from "react-date-object/calendars/arabic";
import arabic_ar from "react-date-object/locales/arabic_ar";

export default function AddCusgarantor({ lang }) {
  const [customerList, setCustomerList] = useState([]);
  const [customer, setCustomer] = useState();
  const [fullName, setFullName] = useState();
  const [releaseDate, setReleaseDate] = useState();
  const [source, setSource] = useState();
  const [idNum, setIdNum] = useState();
  const [DoB, setDoB] = useState();
  const [gender, setGender] = useState();
  const [mobileNum, setMobileNum] = useState();
  const [email, setEmail] = useState();
  const [nationality, setNationality] = useState();
  const country = lang.Saudi_Arabia;
  const [city, setCity] = useState();
  const [states, setStates] = useState([]);
  const [fullAddress, setFullAddress] = useState();
  const [occupation, setOccupation] = useState();
  const [employer, setEmployer] = useState();
  const [designation, setDesignation] = useState();
  const [description, setDescription] = useState();
  const [branchId, setBranchId] = useState();
  const [nationalities, setNationalities] = useState([]);
  const [selectedCalendar, setSelectedCalendar] = useState("arabic");

  const navigate = useNavigate();

  const handleCalendarChange = (calendarType) => {
    setSelectedCalendar(calendarType);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleSubmit = () => {
    try {
      axios
        .post("https://api.amancredits.com/create-guarantor", {
          customer_id: customer,
          FullName: fullName,
          Valid_id: idNum,
          release_date: releaseDate,
          source: source,
          phone_no: mobileNum,
          gender: gender,
          DoB: DoB,
          nationality: nationality,
          country: country,
          city: city,
          address: fullAddress,
          email: email,
          occupation: occupation,
          designation: designation,
          employer: employer,
          description: description,
          branch_id: branchId,
        })
        .then((res) => {
          if (res.status === 200) {
            setFullName("");
            setIdNum("");
            setMobileNum("");
            setGender("");
            setDoB("");
            setNationality("");
            setCity("");
            setFullAddress("");
            setEmail("");
            setOccupation("");
            setDescription("");
            setDesignation("");
            setEmployer("");
            navigate(-1);
            toast.success(res.data.Message, {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else {
            console.log("error occured while uploading");
            toast.error(res.data.Error, {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        })
        .catch((err) => {
          console.error(err);
          toast.error("Error Occured", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (branchId) {
      try {
        axios
          .get("https://api.amancredits.com/get-user/customer", {
            params: {
              status: 1,
              branch_id: branchId,
            },
          })
          .then((res) => {
            setCustomerList(res.data.result);
          })
          .catch((err) => {
            console.error(err);
          });
      } catch (err) {
        console.error(err);
      }
    }
    try {
      axios
        .get("https://api.amancredits.com/get-states")
        .then((res) => {
          setStates(res.data.result);
        })
        .catch((err) => {
          console.error(err);
        });
      axios
        .get("https://api.amancredits.com/get-nationalities")
        .then((res) => {
          setNationalities(res.data.result);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }
  }, [branchId]);
  useEffect(() => {
    setBranchId(
      JSON.parse(JSON.parse(sessionStorage.getItem("aman-admin")).admin).id
    );
  }, []);
  return (
    <div>
      <main id="main" class="main">
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <div class="pagetitle">
          <h1>{lang.Customer_Management || "Customer Management"}t</h1>
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                >
                  <path d="m21.743 12.331-9-10c-.379-.422-1.107-.422-1.486 0l-9 10a.998.998 0 0 0-.17 1.076c.16.361.518.593.913.593h2v7a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-4h4v4a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-7h2a.998.998 0 0 0 .743-1.669z"></path>
                </svg>
              </li>
              <li class="breadcrumb-item active">
                {lang.Customer_Management || "Customer Management"}
              </li>
              <li class="breadcrumb-item">
                {lang.Add_New_Guarantor || "Add New Guarantor"}
              </li>
            </ol>
          </nav>
        </div>

        <section class="section">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex mt-3 mb-3">
                    <h4 class="headingh4 fw-bold">
                      {lang.Add_New_Guarantor || "Add Guarantor"}
                    </h4>
                  </div>

                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    <div className="d-flex flex-column-reverse flex-lg-row mt-3 mb-3 justify-content-end">
                      <label className="select-calendar mt-3 mt-lg-0">
                        <span style={{ fontSize: "14px" }}>
                          {lang.select_calendar || "Select Calendar"}:
                        </span>
                        <div className="form-check">
                          <input
                            type="radio"
                            className="form-check-input"
                            id="arabicRadio"
                            value="arabic"
                            checked={selectedCalendar === "arabic"}
                            onChange={() => handleCalendarChange("arabic")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="arabicRadio"
                          >
                            {lang.arabic || "Arabic"}
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            type="radio"
                            className="form-check-input"
                            id="gregorianRadio"
                            value="gregorian"
                            checked={selectedCalendar === "gregorian"}
                            onChange={() => handleCalendarChange("gregorian")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="gregorianRadio"
                          >
                            {lang.gregorian || "Gregorian"}
                          </label>
                        </div>
                      </label>
                      <div className="d-flex justify-content-end">
                        <button
                          className="btn-save"
                          type="button"
                          onClick={handleCancel}
                        >
                          {lang.Cancel || "Cancel"}
                        </button>
                        <button type="submit" className="btn-cancel ms-4">
                          {lang.Save_And_Publish || "Save & Publish"}
                        </button>
                      </div>
                    </div>
                    <div class="row mb-3 d-flex justify-content-center">
                      <div class="col-lg-8">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Select_Customer || "Select Customer"}:
                          </span>
                          <select
                            class="form-select"
                            aria-label="Default select example"
                            onChange={(e) => setCustomer(e.target.value)}
                            value={customer}
                            required
                          >
                            <option selected disabled></option>
                            {customerList.map((customer) => (
                              <option key={customer.id} value={customer.id}>
                                {customer.FullName}
                              </option>
                            ))}
                          </select>
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Full_Name || "Full Name"}:
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                            required
                          />
                        </label>
                      </div>
                      <div className="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Valid_Identity_Number ||
                              "Valid Identity Number"}
                            :
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            value={idNum}
                            onChange={(e) => setIdNum(e.target.value)}
                            required
                          />
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Release_Date || "Release Date"}:
                          </span>
                          <DatePicker
                            className="form-control w-100"
                            containerStyle={{
                              width: "100%",
                              height: "100%",
                            }}
                            style={{
                              width: "100%",
                              height: "100%",
                              margin: "0",
                            }}
                            calendar={
                              selectedCalendar === "arabic" ? arabic : ""
                            }
                            locale={
                              selectedCalendar === "arabic" ? arabic_ar : ""
                            }
                            value={releaseDate}
                            onChange={(e) => {
                              const dateObject = new Date(e);
                              setReleaseDate(dateObject);
                            }}
                          />
                        </label>
                      </div>
                      <div className="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Source || "Source"}:
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            value={source}
                            onChange={(e) => setSource(e.target.value)}
                            required
                          />
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-4">
                        <lable className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Gender || "Gender"}:
                          </span>
                          <select
                            name=""
                            id=""
                            className="form-select"
                            onChange={(e) => setGender(e.target.value)}
                          >
                            <option value="" selected></option>
                            <option value="Male">{lang.Male || "Male"}</option>
                            <option value="Female">
                              {lang.Female || "Female"}
                            </option>
                          </select>
                        </lable>
                      </div>

                      <div className="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Date_of_Birth || "Date of Birth"}:
                          </span>
                          <DatePicker
                            className="form-control w-100"
                            containerStyle={{
                              width: "100%",
                              height: "100%",
                            }}
                            style={{
                              width: "100%",
                              height: "100%",
                              margin: "0",
                            }}
                            calendar={
                              selectedCalendar === "arabic" ? arabic : ""
                            }
                            locale={
                              selectedCalendar === "arabic" ? arabic_ar : ""
                            }
                            value={DoB}
                            onChange={(e) => {
                              const dateObject = new Date(e);
                              setDoB(dateObject);
                            }}
                          />
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Mobile_Number || "Mobile Number"}:
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            value={mobileNum}
                            onChange={(e) => setMobileNum(e.target.value)}
                            required
                          />
                        </label>
                      </div>

                      <div className="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Email || "Email"}:
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Nationality || "Nationality"}:
                          </span>
                          <select
                            name="nationality"
                            className="form-select"
                            aria-label="Default select example"
                            value={nationality}
                            onChange={(e) => setNationality(e.target.value)}
                            required
                          >
                            <option selected disabled value=""></option>
                            {nationalities.map((nationality, index) => (
                              <option
                                key={index}
                                value={
                                  lang.Lang === "en"
                                    ? nationality.country_enNationality
                                    : nationality.country_arNationality
                                }
                              >
                                {lang.Lang === "en"
                                  ? nationality.country_enNationality
                                  : nationality.country_arNationality}
                              </option>
                            ))}
                          </select>
                        </label>
                      </div>

                      <div className="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Country || "Country"}:
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            value={country}
                            required
                          />
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.State || "State"}:
                          </span>
                          <select
                            name=""
                            id=""
                            className="form-select"
                            onChange={(e) => setCity(e.target.value)}
                          >
                            <option selected disabled></option>
                            {states.map((state) => (
                              <option key={state.city_id} value={state.city_id}>
                                {lang.Lang === "en"
                                  ? state.name_en
                                  : state.name_ar}
                              </option>
                            ))}
                          </select>
                        </label>
                      </div>

                      <div className="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Full_Address || "Full Address"}:
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            value={fullAddress}
                            onChange={(e) => setFullAddress(e.target.value)}
                            required
                          />
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Occupation || "Occupation"}:
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            value={occupation}
                            onChange={(e) => setOccupation(e.target.value)}
                            required
                          />
                        </label>
                      </div>

                      <div className="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Employer_Name || "Employer Name"}:
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            value={employer}
                            onChange={(e) => setEmployer(e.target.value)}
                            required
                          />
                        </label>
                      </div>
                    </div>
                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-8">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Designation || "Designation"}:
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            value={designation}
                            onChange={(e) => setDesignation(e.target.value)}
                            required
                          />
                        </label>
                      </div>
                    </div>
                    <div class="row mb-3 d-flex justify-content-center">
                      <div class="col-lg-8">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Description || "Description"}:
                          </span>
                          <textarea
                            class="form-control"
                            rows="5"
                            onChange={(e) => setDescription(e.target.value)}
                            value={description}
                            required
                          ></textarea>
                        </label>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
