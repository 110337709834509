import React, { useEffect, useState } from "react";
import Table from "../components/Table";
import axios from "axios";
import DatePicker from "react-multi-date-picker";
import arabic from "react-date-object/calendars/arabic";
import arabic_ar from "react-date-object/locales/arabic_ar";
import { useNavigate } from "react-router-dom";

export default function ListOfContract({ lang, path, heading, status, type }) {
  const [contractList, setContractList] = useState([]);
  const [branchId, setBranchId] = useState();
  const [filter, setFilter] = useState([]);
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [investorList, setInvestorList] = useState([]);
  const [investor, setInvestor] = useState();
  const [totalContractValue, setTotalContractValue] = useState();

  const navigate = useNavigate();

  function convertDateFormat(inputDate) {
    const dateObject = new Date(inputDate);
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };

    const locale = lang.Lang === "en" ? "en-US" : "ar-SA";

    const formattedDate = dateObject.toLocaleDateString(locale, options);
    return formattedDate;
  }

  const columns = [
    {
      header: lang.Clients || "Clients",
      accessorKey: "customer_name",
    },
    {
      header: lang.Investors || "Clients",
      accessorKey: "investor_name",
    },
    {
      header: lang.date || "Date",
      accessorKey: "date_contract",
      cell: (row) => <p>{convertDateFormat(row.getValue())}</p>,
    },
    {
      header: lang.Amount || "Amount",
      cell: (row) => (
        <p>{row.row.original.total_value - row.row.original.profit}</p>
      ),
    },
    {
      header: lang.Profit || "Profit",
      accessorKey: "profit",
    },
    {
      header: lang.Total || "Total",
      accessorKey: "total_value",
    },
    {
      header: lang.Paid_Up || "Paid up",
      accessorKey: "total_paid",
    },
    {
      header: lang.Residual || "Residual",
      cell: (row) => (
        <p>{row.row.original.total_value - row.row.original.total_paid}</p>
      ),
    },
    {
      header: lang.Actions || "Actions",
      cell: (row) => (
        <div className="dropdown top-0 start-0">
          <button
            className="btn btn-primary dropdown-toggle"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {lang.Actions || "Actions"}
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <button
                type="button"
                onClick={() =>
                  navigate(
                    `/dashboard/contracts/valid-contract/complete-contract/${row.row.original.id}`
                  )
                }
                className="dropdown-item mt-0"
                disabled={row.row.original.status != 2}
              >
                {lang.complete_contract || "Complete Contract"}
              </button>
            </li>
          </ul>
        </div>
      ),
    },
  ];
  useEffect(() => {
    if (filter !== "from_to") {
      setFrom();
      setTo();
    }
    if (branchId) {
      try {
        axios
          .get("https://api.amancredits.com/contract-reports", {
            params: {
              branch_id: branchId,
              filter,
              from,
              to,
              investor,
              status,
              type,
            },
          })
          .then((res) => {
            setContractList(res.data.result);
          })
          .catch((err) => {
            console.error(err);
          });
      } catch (err) {
        console.error(err);
      }

      axios
        .get("https://api.amancredits.com/investor", {
          params: {
            branch_id: branchId,
          },
        })
        .then((res) => {
          setInvestorList(res.data.result);
        })
        .catch((err) => {
          console.error(err);
        });
      axios
        .get("https://api.amancredits.com/get-total-amount", {
          params: {
            branch_id: branchId,
            investor_id: investor,
          },
        })
        .then((res) => {
          setTotalContractValue(res.data.result);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [branchId, filter, from, to, investor, status, type]);

  useEffect(() => {
    setBranchId(
      JSON.parse(JSON.parse(sessionStorage.getItem("aman-admin")).admin).id
    );
  }, []);
  return (
    <div>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>{lang.Reports || "Reports"}</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                >
                  <path d="m21.743 12.331-9-10c-.379-.422-1.107-.422-1.486 0l-9 10a.998.998 0 0 0-.17 1.076c.16.361.518.593.913.593h2v7a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-4h4v4a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-7h2a.998.998 0 0 0 .743-1.669z"></path>
                </svg>
              </li>
              <li className="breadcrumb-item">{lang.Reports || "Reports"}</li>
              <li className="breadcrumb-item active">{path}</li>
            </ol>
          </nav>
        </div>

        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="mt-3 mb-3">
                    <div className="rr">{heading}</div>

                    <div className="row row justify-content-end">
                      {filter === "from_to" && (
                        <>
                          <DatePicker
                            className="form-control w-100"
                            placeholder="From"
                            style={{
                              left: "35%",
                              top: "5px",
                              height: "auto",
                            }}
                            containerStyle={{
                              height: "auto",
                            }}
                            // calendar={
                            //   selectedCalendar === "arabic" ? arabic : ""
                            // }
                            // locale={
                            //   selectedCalendar === "arabic" ? arabic_ar : ""
                            // }
                            // value={currentDate}
                            onChange={(e) => {
                              const dateObject = new Date(e);
                              setFrom(dateObject);
                            }}
                          />
                          <DatePicker
                            className="form-control w-100"
                            placeholder="To"
                            style={{
                              left: "45%",
                              top: "5px",
                              height: "auto",
                            }}
                            containerStyle={{
                              height: "auto",
                            }}
                            // calendar={
                            //   selectedCalendar === "arabic" ? arabic : ""
                            // }
                            // locale={
                            //   selectedCalendar === "arabic" ? arabic_ar : ""
                            // }
                            // value={currentDate}
                            onChange={(e) => {
                              const dateObject = new Date(e);
                              setTo(dateObject);
                            }}
                          />
                        </>
                      )}
                      <div className="d-flex flex-column align-items-end">
                        <select
                          onChange={(e) => setInvestor(e.target.value)}
                          name=""
                          id=""
                          className="form-data col-4 btn-export"
                          style={{ top: "48px" }}
                        >
                          <option value="">{lang.all_investors || "All Investors"}</option>
                          {investorList.map((investor, index) => (
                            <option key={index} value={investor.id}>
                              {investor.FullName}
                            </option>
                          ))}
                        </select>
                        <select
                          onChange={(e) => setFilter(e.target.value)}
                          name=""
                          id=""
                          className="form-data btn-export me-0 mb-4 mb-md-0 mt-2 col-4 " 
                        >
                          <option value="">
                            {lang.All_Time || "All Time"}
                          </option>
                          <option value="today">{lang.Today || "Today"}</option>
                          <option value="this_week">
                            {lang.This_Week || "This Week"}
                          </option>
                          <option value="this_month">
                            {lang.This_Month || "This Month"}
                          </option>
                          <option value="this_year">
                            {lang.This_Year || "This Year"}
                          </option>
                          <option value="from_to">From To</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div classNames="d-flex mt-3 mb-3">
                    <Table
                      lang={lang}
                      data={contractList}
                      columns={columns}
                      totalContractValue={totalContractValue}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
