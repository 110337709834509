import React from 'react'

export default function AddNewCommision() {
  return (
    <>
      <main id="main" className="main">

        <div className="pagetitle">
          <h1>General Management</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"> <img src="/img/homeicon.png" alt="logo" className="sidebaricon" /></li>
              <li className="breadcrumb-item">General Management</li>
              <li className="breadcrumb-item active">Contract Commision</li>
              <li className="breadcrumb-item ">Add Contract Commision</li>

            </ol>
          </nav>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-lg-12">

              <div className="card">
                <div className="card-body">
                  <div className="d-flex mt-3 mb-3">
                    <h4 className="headingh4 fw-bold">Add Contract Commision </h4>

                  </div>

                  <div className="d-flex mt-3 mb-3 justify-content-end">
                    <button type="submit" className="btn-save">Save as Draft </button>
                    <button type="submit" className="btn-cancel">Save & Publish </button>
                  </div>
                  <form>
                    <div className="row mb-3 d-flex justify-content-center mt-5">

                      <div className="col-sm-4">
                        <input type="text" className="form-control text-primary" placeholder="Contact no" />
                      </div>
                      <div className="col-sm-4">
                        <select id="disabledSelect" className="form-select text-primary"> <option>Investor</option></select>
                      </div>
                    </div>


                    <div className="row mb-3 d-flex justify-content-center">

                      <div className="col-sm-4">
                        <select id="disabledSelect" className="form-select text-primary"> <option>Commision</option></select>
                      </div>
                      <div className="col-sm-4">
                        <select id="disabledSelect" className="form-select text-primary"> <option>Period</option></select>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-sm-4">
                        <select id=" form-select" className="form-select text-primary"> <option>Contract System</option></select>
                      </div>

                      <div className="col-sm-4">
                        <input type="text" className="form-control" placeholder="Mobile Number" />
                      </div>
                    </div>
                    {/* <div className="row mb-3 d-flex justify-content-center">
      
        <div className="col-sm-4">
         <input type="text" className="form-control" placeholder="Mobile Number"/>
       </div>
  <div className="col-sm-4">
       <select id="disabledSelect" className="form-select text-primary"> <option>Mobile Number</option></select>
       </div>
    </div> */}

                    {/* <div className="row mb-3 d-flex justify-content-center">
      
        <div className="col-sm-4">
         <input type="text" className="form-control" placeholder="Paid Up"/>
       </div>
 
 
       <div className="col-sm-4">
         <input type="text" className="form-control"  placeholder="Installment Amount"/>
       </div>
 
    </div>
      */}


                  </form>

                </div>
              </div>

            </div>
          </div>
        </section>

      </main>

    </>
  )
}
