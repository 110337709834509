import React, { useState, useEffect } from "react";
// import IndeterminateCheckbox from '../components/IndeterminateChechbox';
import Table from "../components/Table";
import axios from "axios";

export default function InvestorCustomerReport({ lang }) {
  const [contractList, setContractList] = useState([]);
  const [branchId, setBranchId] = useState();
  const [investorList, setInvestorList] = useState([]);
  const [investor, setInvestor] = useState();
  const [totalContractValue, setTotalContractValue] = useState();

  const fetchContracts = () => {
    try {
      axios
        .get("https://api.amancredits.com/get-contracts", {
          params: {
            branch_id: branchId,
            investor_id: investor,
          },
        })
        .then((res) => {
          setContractList(res.data.result);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const columns = [
    {
      header: lang.Investor_Name || "Investor Name",
      accessorKey: "investor_name",
    },
    {
      header: lang.Customer_Name || "Customer Name",
      accessorKey: "customer_name",
    },
    {
      header: `${lang.Customer} ${lang.Identity_Number}` || "Customer ID",
      accessorKey: "customer_validID",
    },
    {
      header: `${lang.Customer} ${lang.Mobile_Number}` || "Customer Mobile Number",
      accessorKey: "customer_mobile",
    },
    {
      header: lang.Contract_Value || "Contract Value",
      accessorKey: "total_value",
    },
  ];
  useEffect(() => {
    if (branchId) {
      fetchContracts();
      axios
        .get("https://api.amancredits.com/investor", {
          params: {
            branch_id: branchId,
          },
        })
        .then((res) => {
          setInvestorList(res.data.result);
        })
        .catch((err) => {
          console.error(err);
        });

      axios
        .get("https://api.amancredits.com/get-total-amount", {
          params: {
            branch_id: branchId,
            investor_id: investor,
          },
        })
        .then((res) => {
          setTotalContractValue(res.data.result.totalInvestorAmount);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchId, investor]);

  useEffect(() => {
    setBranchId(
      JSON.parse(JSON.parse(sessionStorage.getItem("aman-admin")).admin).id
    );
  }, []);
  return (
    <>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>{lang.Reports || "Reports"}</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                >
                  <path d="m21.743 12.331-9-10c-.379-.422-1.107-.422-1.486 0l-9 10a.998.998 0 0 0-.17 1.076c.16.361.518.593.913.593h2v7a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-4h4v4a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-7h2a.998.998 0 0 0 .743-1.669z"></path>
                </svg>
              </li>
              <li className="breadcrumb-item">{lang.Reports || "Reports"}</li>
              <li className="breadcrumb-item active">
                {lang.Investor || "Investor"}
              </li>
            </ol>
          </nav>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="mt-3 mb-3">
                    <div className="rr">
                      {lang.Investor_List || "Investor List"}
                    </div>
                    <div className="row justify-content-end">
                      <select
                        onChange={(e) => setInvestor(e.target.value)}
                        name=""
                        id=""
                        className="form-data col-4 btn-export"
                        style={{ top: "48px" }}
                      >
                        <option value="">All Investors</option>
                        {investorList.map((investor, index) => (
                          <option key={index} value={investor.id}>
                            {investor.FullName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div classNames="d-flex mt-3 mb-3">
                    <Table
                      totalContractValue={totalContractValue}
                      lang={lang}
                      data={contractList}
                      columns={columns}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
