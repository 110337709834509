import React, { useEffect, useState } from "react";
import Select from "react-select";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function LoanContract({ lang }) {
  const [guarantorList, setGuarantorList] = useState([]);
  const [customer, setCustomer] = useState();
  const [customerId, setCustomerId] = useState();
  const [guarantor1, setGuarantor1] = useState();
  const [guarantor2, setGuarantor2] = useState();
  const [poolList, setPoolList] = useState([]);
  const [contractSystem, setContractsystem] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const [currentDate, setCurrentDate] = useState("");
  const [selectedScheduleType, setSelectedScheduleType] = useState();
  const [nextScheduledDate, setNextScheduledDate] = useState(null);
  const [profit, setProfit] = useState();
  const [contractValue, setContractValue] = useState();
  const [loanRequestList, setLoanRequestList] = useState([]);
  const [requiredAmount, setRequiredAmount] = useState();
  const [loanRequestId, setLoanRequestId] = useState();
  const [poolId, setPoolId] = useState();
  const [poolAmount, setPoolAmount] = useState();
  const [profitValue, setProfitValue] = useState();
  const [downPayment, setDownPayment] = useState();
  const [finalInstallment, setFinalInstallment] = useState();
  const [remaining, setRemaining] = useState();
  const [noInstallment, setNoInstallment] = useState();
  const [account] = useState();
  const [contractType, setContractType] = useState();
  const [branchId, setBranchId] = useState();

  const navigate = useNavigate();

  const scheduleTypeList = ["Monthly", "Weekly", "Half-Yearly", "Yearly"];

  const handleSubmit = () => {
    try {
      axios
        .post("https://api.amancredits.com/create-installment-contract", {
          contractType,
          customer: customerId,
          loanRequestId,
          poolId,
          requiredAmount,
          guarantor: [guarantor1, guarantor2],
          account,
          currentDate,
          selectedScheduleType,
          nextScheduledDate,
          contractValue,
          profit,
          downPayment,
          finalInstallment,
          noInstallment,
          remaining,
          branch_id: branchId,
        })
        .then((res) => {
          setContractType("");
          console.log("success");
          navigate("/dashboard/contracts/valid-contract");
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const calculateNextScheduledDate = () => {
    if (!selectedScheduleType) {
      return;
    }
    // Get the current date
    const currentDate = new Date();

    // Calculate the new date based on the selected schedule type
    switch (selectedScheduleType) {
      case "Monthly":
        currentDate.setMonth(currentDate.getMonth() + 1);
        break;
      case "Weekly":
        currentDate.setDate(currentDate.getDate() + 7);
        break;
      case "Half-Yearly":
        currentDate.setMonth(currentDate.getMonth() + 6);
        break;
      case "Yearly":
        currentDate.setFullYear(currentDate.getFullYear() + 1);
        break;
      default:
        break;
    }

    // Set the new date in the component's state
    setNextScheduledDate(currentDate.toISOString().slice(0, 10));
  };

  useEffect(() => {
    const currentDateObject = new Date();

    // Format the date in YYYY/MM/DD
    const formattedDate = `${currentDateObject.getFullYear()}-${(
      currentDateObject.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDateObject
      .getDate()
      .toString()
      .padStart(2, "0")}`;

    // Set the formatted date in the component's state
    setCurrentDate(formattedDate);
    try {
      axios
        .get("https://api.amancredits.com/get-pools", {
          params: {
            branch_id: branchId,
          },
        })
        .then((res) => {
          const mappedOptions = res.data.result.map((item) => ({
            value: item.pool_id,
            label: item.title,
            poolAmount: item.total_value,
          }));
          setPoolList(mappedOptions);
        })
        .catch((err) => {
          console.error(err);
        });
      axios
        .get("https://api.amancredits.com/get-contract-system")
        .then((res) => {
          setContractsystem(res.data.result);
        })
        .catch((err) => {
          console.error(err);
        });
      axios
        .get("https://api.amancredits.com/get-accounts", {
          params: {
            branch_id: branchId,
          },
        })
        .then((res) => {
          setAccountList(res.data.result);
        })
        .catch((err) => {
          console.error(err);
        });
      axios
        .get("https://api.amancredits.com/get-contract-profit")
        .then((res) => {
          setProfit(res.data.result[0].profit_percentage);
        })
        .catch((err) => {
          console.error(err);
        });
      axios
        .get("https://api.amancredits.com/get-pending-loan-request", {
          params: {
            branch_id: branchId,
          },
        })
        .then((res) => {
          const mappedOptions = res.data.result.map((item) => ({
            value: item.request_id,
            label: item.loan_request_title,
            requestAmount: item.loan_request_amount,
            customerName: item.FullName,
            customerID: item.id,
          }));
          setLoanRequestList(mappedOptions);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }
  }, [branchId]);
  useEffect(() => {
    setBranchId(
      JSON.parse(JSON.parse(sessionStorage.getItem("aman-admin")).admin).id
    );
  }, []);
  useEffect(() => {
    try {
      axios
        .get("https://api.amancredits.com/get-guarantor?customer_id=" + customerId)
        .then((res) => {
          setGuarantorList(res.data.result);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }
  }, [customerId]);
  useEffect(() => {
    calculateNextScheduledDate();
  }, [selectedScheduleType]);
  useEffect(() => {
    if (requiredAmount) {
      setContractValue(
        parseInt(requiredAmount) + (profit * requiredAmount) / 100
      );
      setProfitValue((profit * requiredAmount) / 100);
    }
  }, [requiredAmount]);
  useEffect(() => {
    if (remaining !== null && noInstallment) {
      setFinalInstallment(parseFloat(remaining / noInstallment));
    } else {
      setFinalInstallment(null);
    }
  }, [remaining, noInstallment]);
  useEffect(() => {
    if (remaining !== null && finalInstallment) {
      setNoInstallment(parseInt(remaining / finalInstallment).toFixed(0));
      //   console.log((parseInt(remaining / finalInstallment)).toFixed(0))
    } else {
      setNoInstallment(null);
    }
  }, [remaining, finalInstallment]);
  useEffect(() => {
    if (downPayment && contractValue) {
      setRemaining(contractValue - (downPayment || 0));
    } else {
      setRemaining(null);
    }
  }, [downPayment, contractValue]);
  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>{lang.General_Management || "General Management"}</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <img src="/img/homeicon.png" alt="logo" className="sidebaricon" />
            </li>
            <li className="breadcrumb-item">
              {lang.General_Management || "General Management"}
            </li>
            <li className="breadcrumb-item">{lang.Contracts || "Contracts"}</li>
            <li className="breadcrumb-item">
              {lang.New_Installment_Contracts || "New Installments Contracts"}
            </li>
            <li className="breadcrumb-item active">
              {lang.Contract_Information || "Contract Information"}
            </li>
          </ol>
        </nav>
      </div>

      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <div className="d-flex mt-3 mb-3 justify-content-end">
                    <button className="btn-save">
                      {lang.Cancel || "Cancel"}
                    </button>
                    <button type="submit" className="btn-cancel">
                      {lang.Save_And_Next || "Save & Next"}
                    </button>
                  </div>

                  <div className="row mb-3 d-flex justify-content-center">
                    <div className="col-sm-4">
                      <label htmlFor="loanRequest" className="w-100">
                        <span style={{ fontSize: "14px" }}>
                          {lang.Loan_Request || "Loan Request"}:
                        </span>
                        <Select
                          id="loanRequest"
                          options={loanRequestList}
                          required
                          onChange={(e) => {
                            setLoanRequestId(e.value);
                            setRequiredAmount(e.requestAmount);
                            setCustomer(e.customerName);
                            setCustomerId(e.customerID);
                            console.log(e.customerID);
                          }}
                        />
                      </label>
                    </div>
                    <div className="col-sm-4">
                      <label htmlFor="requiredAmount" className="w-100">
                        <span style={{ fontSize: "14px" }}>
                          {lang.Required_Amount || "Required Amount"}:
                        </span>
                        <input
                          id="requiredAmount"
                          type="text"
                          className="form-control"
                          value={requiredAmount}
                          disabled
                          required
                        />
                      </label>
                    </div>
                  </div>

                  <div className="row mb-3 d-flex justify-content-center">
                    <div className="col-sm-4">
                      <label htmlFor="client" className="w-100">
                        <span style={{ fontSize: "14px" }}>
                          {lang.Client || "Client"}:
                        </span>
                        <input
                          id="client"
                          type="text"
                          className="form-control"
                          value={customer}
                          disabled
                          required
                        />
                      </label>
                    </div>
                    <div className="col-sm-4">
                      <label htmlFor="guarantor1" className="w-100">
                        <span style={{ fontSize: "14px" }}>
                          {lang.Select_Guarantor_1 || "Select Guarantor"}:
                        </span>
                        <select
                          id="guarantor1"
                          className="form-select"
                          aria-label="Default select example"
                          onChange={(e) => setGuarantor1(e.target.value)}
                        >
                          <option selected disabled>
                            Select Guarantor
                          </option>
                          {guarantorList.map((guarantor) => (
                            <option key={guarantor.id} value={guarantor.id}>
                              {guarantor.FullName}
                            </option>
                          ))}
                        </select>
                      </label>
                    </div>
                  </div>

                  <div className="row mb-3 d-flex justify-content-center">
                    <div className="col-sm-4">
                      <label htmlFor="guarantor2" className="w-100">
                        <span style={{ fontSize: "14px" }}>
                          {lang.Select_Guarantor_2 || "Select Guarantor"}:
                        </span>
                        <select
                          id="guarantor2"
                          className="form-select"
                          aria-label="Default select example"
                          onChange={(e) => setGuarantor2(e.target.value)}
                        >
                          <option selected disabled>
                            Select Guarantor
                          </option>
                          {guarantorList.map((guarantor) => (
                            <option key={guarantor.id} value={guarantor.id}>
                              {guarantor.FullName}
                            </option>
                          ))}
                        </select>
                      </label>
                    </div>
                    <div className="col-sm-4">
                      <label htmlFor="contractType" className="w-100">
                        <span style={{ fontSize: "14px" }}>
                          {lang.Contract_System || "Select Contract System"}:
                        </span>
                        <select
                          id="contractType"
                          className="form-select"
                          aria-label="Default select example"
                          onChange={(e) => setContractType(e.target.value)}
                        >
                          <option selected disabled></option>
                          {contractSystem.map((system) => (
                            <option key={system.type_id} value={system.type_id}>
                              {system.type}
                            </option>
                          ))}
                        </select>
                      </label>
                    </div>
                  </div>

                  <div className="row mb-3 d-flex justify-content-center">
                    <div className="col-sm-4">
                      <label htmlFor="poolList" className="w-100">
                        <span style={{ fontSize: "14px" }}>
                          {lang.Pool_List || "Pool List"}:
                        </span>
                        <Select
                          id="poolList"
                          options={poolList}
                          required
                          onChange={(e) => {
                            setPoolId(e.value);
                            setPoolAmount(e.poolAmount);
                          }}
                        />
                      </label>
                    </div>
                    <div className="col-sm-4">
                      <label htmlFor="poolAmount" className="w-100">
                        <span style={{ fontSize: "14px" }}>
                          {lang.Pool_Amount || "Pool Amount"}:
                        </span>
                        <input
                          id="poolAmount"
                          type="text"
                          className="form-control"
                          value={poolAmount}
                          disabled
                          required
                        />
                      </label>
                    </div>
                  </div>

                  <div className="row mb-3 d-flex justify-content-center">
                    <div className="col-sm-4">
                      <label htmlFor="account" className="w-100">
                        <span style={{ fontSize: "14px" }}>
                          {lang.Select_Account || "Select Account"}:
                        </span>
                        <select
                          id="account"
                          className="form-select"
                          aria-label="Default select example"
                        >
                          <option selected disabled></option>
                          {accountList.map((account) => (
                            <option key={account.id} value={account.id}>
                              {account.account_name}
                            </option>
                          ))}
                        </select>
                      </label>
                    </div>
                    <div className="col-sm-4">
                      <label htmlFor="contractDate" className="w-100">
                        <span style={{ fontSize: "14px" }}>
                          {lang.Date_of_Contract ||
                            "Date of Contract (YYYY/MM/DD)"}
                          :
                        </span>
                        <input
                          id="contractDate"
                          type="text"
                          className="form-control"
                          value={currentDate}
                          required
                        />
                      </label>
                    </div>
                  </div>

                  <div className="row mb-3 d-flex justify-content-center">
                    <div className="col-sm-4">
                      <label htmlFor="scheduleType" className="w-100">
                        <span style={{ fontSize: "14px" }}>
                          {lang.Schedule_Type || "Schedule Type"}:
                        </span>
                        <select
                          id="scheduleType"
                          className="form-select"
                          aria-label="Default select example"
                          value={selectedScheduleType}
                          onChange={(e) => {
                            setSelectedScheduleType(e.target.value);
                          }}
                          required
                        >
                          <option selected disabled></option>
                          {scheduleTypeList.map((type, index) => (
                            <option key={index} value={type}>
                              {type}
                            </option>
                          ))}
                        </select>
                      </label>
                    </div>
                    <div className="col-sm-4">
                      <label htmlFor="nextScheduledDate" className="w-100">
                        <span style={{ fontSize: "14px" }}>
                          {lang.Date_of_1st_Installment ||
                            "Date of 1st installment"}
                          :
                        </span>
                        <input
                          id="nextScheduledDate"
                          type="text"
                          className="form-control"
                          value={nextScheduledDate}
                          disabled
                          required
                        />
                      </label>
                    </div>
                  </div>

                  <div className="row mb-3 d-flex justify-content-center">
                    <div className="col-sm-4">
                      <label htmlFor="contractValue" className="w-100">
                        <span style={{ fontSize: "14px" }}>
                          {lang.Contract_Value || "Contract Value"}:
                        </span>
                        <input
                          id="contractValue"
                          type="text"
                          className="form-control"
                          value={contractValue}
                          disabled
                          required
                        />
                      </label>
                    </div>
                    <div className="col-sm-4">
                      <label htmlFor="profit" className="w-100">
                        <span style={{ fontSize: "14px" }}>
                          {lang.Profit || "Profit"}:
                        </span>
                        <input
                          id="profit"
                          type="text"
                          className="form-control"
                          value={profit}
                          disabled
                          required
                        />
                      </label>
                    </div>
                  </div>

                  <div className="row mb-3 d-flex justify-content-center">
                    <div className="col-sm-4">
                      <label htmlFor="profitValue" className="w-100">
                        <span style={{ fontSize: "14px" }}>Profit:</span>
                        <input
                          id="profitValue"
                          type="text"
                          className="form-control"
                          value={profitValue}
                          required
                        />
                      </label>
                    </div>
                    <div className="col-sm-4">
                      <label htmlFor="downPayment" className="w-100">
                        <span style={{ fontSize: "14px" }}>
                          {lang.Down_Payment || "Down Payment"}:
                        </span>
                        <input
                          id="downPayment"
                          type="text"
                          className="form-control"
                          value={downPayment}
                          onChange={(e) => setDownPayment(e.target.value)}
                          required
                        />
                      </label>
                    </div>
                  </div>

                  <div className="row mb-3 d-flex justify-content-center">
                    <div className="col-sm-4">
                      <label htmlFor="remaining" className="w-100">
                        <span style={{ fontSize: "14px" }}>
                          {lang.Remaining_amount || "Remaining Amount"}:
                        </span>
                        <input
                          id="remaining"
                          type="text"
                          className="form-control"
                          value={remaining}
                          disabled
                          required
                        />
                      </label>
                    </div>
                    <div className="col-sm-4">
                      <label htmlFor="premium" className="w-100">
                        <span style={{ fontSize: "14px" }}>
                          {lang.Amount_of_Premium || "Amount of Premium"}:
                        </span>
                        <input
                          id="premium"
                          type="text"
                          className="form-control"
                          value={finalInstallment}
                          disabled
                          required
                        />
                      </label>
                    </div>
                  </div>

                  <div className="row mb-3 d-flex justify-content-center">
                    <div className="col-sm-4">
                      <label htmlFor="noInstallment" className="w-100">
                        <span style={{ fontSize: "14px" }}>
                          {lang.Number_of_Installments ||
                            "Number of Installments"}
                          :
                        </span>
                        <input
                          id="noInstallment"
                          type="text"
                          className="form-control"
                          value={noInstallment}
                          onChange={(e) => setNoInstallment(e.target.value)}
                          required
                        />
                      </label>
                    </div>
                    <div className="col-sm-4">
                      <label htmlFor="finalInstallment" className="w-100">
                        <span style={{ fontSize: "14px" }}>
                          {lang.Final_Installment || "Final Installment"}:
                        </span>
                        <input
                          id="finalInstallment"
                          type="text"
                          className="form-control"
                          value={finalInstallment}
                          onChange={(e) => setFinalInstallment(e.target.value)}
                          required
                        />
                      </label>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default LoanContract;
