import React from "react";
import IndeterminateCheckbox from "../components/IndeterminateChechbox";
import Table from "../components/Table";

export default function InvestReport() {
  const data = [
    {
      customerName: "CustomerName",
      occupation: "Occupation Here",
      mobileNumber: "Mobile Number",
    },
    {
      customerName: "CustomerName",
      occupation: "Occupation Here",
      mobileNumber: "Mobile Number",
    },
    {
      customerName: "CustomerName",
      occupation: "Occupation Here",
      mobileNumber: "Mobile Number",
    },
    {
      customerName: "CustomerName",
      occupation: "Occupation Here",
      mobileNumber: "Mobile Number",
    },
    {
      customerName: "CustomerName",
      occupation: "Occupation Here",
      mobileNumber: "Mobile Number",
    },
    {
      customerName: "CustomerName",
      occupation: "Occupation Here",
      mobileNumber: "Mobile Number",
    },
    {
      customerName: "CustomerName",
      occupation: "Occupation Here",
      mobileNumber: "Mobile Number",
    },
    {
      customerName: "CustomerName",
      occupation: "Occupation Here",
      mobileNumber: "Mobile Number",
    },
    {
      customerName: "CustomerName",
      occupation: "Occupation Here",
      mobileNumber: "Mobile Number",
    },
    {
      customerName: "CustomerName",
      occupation: "Occupation Here",
      mobileNumber: "Mobile Number",
    },
    {
      customerName: "CustomerName",
      occupation: "Occupation Here",
      mobileNumber: "Mobile Number",
    },
    {
      customerName: "CustomerName",
      occupation: "Occupation Here",
      mobileNumber: "Mobile Number",
    },
    {
      customerName: "CustomerName",
      occupation: "Occupation Here",
      mobileNumber: "Mobile Number",
    },
    {
      customerName: "CustomerName",
      occupation: "Occupation Here",
      mobileNumber: "Mobile Number",
    },
    {
      customerName: "CustomerName",
      occupation: "Occupation Here",
      mobileNumber: "Mobile Number",
    },
    {
      customerName: "CustomerName",
      occupation: "Occupation Here",
      mobileNumber: "Mobile Number",
    },
  ];
  const columns = [
    {
      id: "select",
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
    },
    {
      header: "Customer Name",
      accessorKey: "customerName",
    },
    {
      header: "Occupation",
      accessorKey: "occupation",
    },
    {
      header: "Mobile Number",
      accessorKey: "mobileNumber",
    },
  ];
  return (
    <>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Reports</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                {" "}
                <img
                  src="/img/homeicon.png"
                  alt="logo"
                  className="sidebaricon"
                />
              </li>
              <li className="breadcrumb-item">Reports</li>
              <li className="breadcrumb-item active">Investment Pool List</li>
            </ol>
          </nav>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex mt-3 mb-3">
                    <div className="rr">Investment Pool List</div>
                    <button className="btn btn-export">
                      Export <img src="/img/export.png" alt="export" />{" "}
                    </button>
                  </div>

                  <div classNames="d-flex mt-3 mb-3">
                    <Table data={data} columns={columns} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
