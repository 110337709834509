import React, { useEffect, useState } from "react";
import IndeterminateCheckbox from "../components/IndeterminateChechbox";
import Table from "../components/Table";
import { Link } from "react-router-dom";
import axios from "axios";

export default function TypeOfLoans({ lang }) {
  const [loanList, setLoanList] = useState([]);
  const [branchId, setBranchId] = useState();

  const columns = [
    {
      id: "select",
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
    },
    {
      header: lang.type_name || "Type Name",
      accessorKey: "request_type",
    },
    {
      header: " ",
      accessorKey: " ",
    },
    {
      header: " ",
      accessorKey: " ",
    },
  ];
  useEffect(() => {
    setBranchId(
      JSON.parse(JSON.parse(sessionStorage.getItem("aman-admin")).admin).id
    );
  }, []);
  useEffect(() => {
    try {
      axios
        .get("https://api.amancredits.com/get-loan-types", {
          params: {
            branch_id: branchId,
          },
        })
        .then((res) => {
          setLoanList(res.data.result);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }
  }, [branchId]);
  return (
    <div>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>
            {lang.Investment_Loan_Management || "Investment/Loan Management"}
          </h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                >
                  <path d="m21.743 12.331-9-10c-.379-.422-1.107-.422-1.486 0l-9 10a.998.998 0 0 0-.17 1.076c.16.361.518.593.913.593h2v7a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-4h4v4a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-7h2a.998.998 0 0 0 .743-1.669z"></path>
                </svg>
              </li>
              <li className="breadcrumb-item">
                {lang.Investment_Loan_Management ||
                  "Investment/Loan Management"}
              </li>
              <li className="breadcrumb-item active">
                {lang.Loan_List || "Loan List"}
              </li>
            </ol>
          </nav>
        </div>

        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="row">
                  <div className="col-9"></div>
                  <div className="col-3">
                    <div></div>
                    <Link to="add-loan-list">
                      <button type="button" className="btn btn-primary ms-4">
                        <img
                          src="img/fi_plus.png"
                          alt=""
                          className="img-fluid me-2"
                        />
                        {lang.Add_Loan_Type || "Add New Loan"}
                      </button>
                    </Link>
                  </div>
                </div>

                <div className="row pt-3">
                  <div className="rr col-9">
                    {lang.Loan_List || "Loan List"}
                  </div>
                </div>
                <div className="card-body">
                  <div classNames="d-flex mt-3 mb-3">
                    <Table lang={lang} data={loanList} columns={columns} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
