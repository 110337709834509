import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import IndeterminateCheckbox from "../components/IndeterminateChechbox";
import Table from "../components/Table";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function PurchaseInventoryList({ lang }) {
  const [inventoryList, setInventoryList] = useState([]);
  const [newQty, setNewQty] = useState();
  const [invToUpdate, setInvToUpdate] = useState({
    inventory_id: "",
    inventory_item: "",
    FullName: "",
    bank_account: "",
    balance: "",
    inventory_price: "",
    inventory_quantity: "",
    type: "",
    investor_id: "",
  });
  const [branchId, setBranchId] = useState();

  const handleQtyUpdate = () => {
    if (invToUpdate.balance < invToUpdate.inventory_price * newQty) {
      alert("Insufficiant Balance");
      return;
    }
    axios
      .put("https://api.amancredits.com/update-item-quantity", {
        invId: invToUpdate.inventory_id,
        quantity: newQty,
        branch_id: branchId,
        investor_id: invToUpdate.investor_id,
        price: invToUpdate.inventory_price * newQty,
      })
      .then((res) => {
        fetchInventory();
        toast.success(res.data.Message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((err) => {
        console.error(err);
        toast.error("Error Occured", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const getItemToUpdate = (inventoryId) => {
    axios
      .get("https://api.amancredits.com/get-item-to-update", {
        params: {
          branch_id: branchId,
          inventoryId,
        },
      })
      .then((res) => {
        setInvToUpdate(res.data.result[0]);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchInventory = () => {
    try {
      axios
        .get("https://api.amancredits.com/get-items", {
          params: {
            branch_id: branchId,
          },
        })
        .then((res) => {
          setInventoryList(res.data.result);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const columns = [
    {
      id: "select",
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
    },
    {
      header: lang.Item || "Item",
      accessorKey: "inventory_item",
    },
    {
      header: lang.category || "Category",
      accessorKey: "type",
    },
    {
      header: lang.Owner || "Owner",
      accessorKey: "investor_name",
    },
    {
      header: lang.Purchase_Price || "Purchase Price",
      accessorKey: "inventory_price",
    },
    {
      header: lang.Quantity || "Quantity",
      accessorKey: "inventory_quantity",
    },
    {
      header: lang.Actions || "Actions",
      cell: (row) => (
        <button
          className="btn btn-primary mx-1"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#myModal"
          onClick={(e) => {
            e.preventDefault();
            getItemToUpdate(row.row.original.id);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#fff"
          >
            <path d="m18.988 2.012 3 3L19.701 7.3l-3-3zM8 16h3l7.287-7.287-3-3L8 13z"></path>
            <path d="M19 19H8.158c-.026 0-.053.01-.079.01-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .896-2 2v14c0 1.104.897 2 2 2h14a2 2 0 0 0 2-2v-8.668l-2 2V19z"></path>
          </svg>
        </button>
      ),
    },
  ];

  useEffect(() => {
    if (branchId) {
      fetchInventory();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchId]);

  useEffect(() => {
    setBranchId(
      JSON.parse(JSON.parse(sessionStorage.getItem("aman-admin")).admin).id
    );
  }, []);

  return (
    <>
      <main id="main" className="main">
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <div className="pagetitle">
          <h1>
            {lang.Investment_Loan_Management || "Investment/Loan Management"}
          </h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                >
                  <path d="m21.743 12.331-9-10c-.379-.422-1.107-.422-1.486 0l-9 10a.998.998 0 0 0-.17 1.076c.16.361.518.593.913.593h2v7a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-4h4v4a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-7h2a.998.998 0 0 0 .743-1.669z"></path>
                </svg>
              </li>
              <li className="breadcrumb-item ">
                {lang.Investment_Loan_Management ||
                  "Investment/Loan Management"}
              </li>
              <li className="breadcrumb-item active">
                {lang.Inventory_List || "Inventory List"}
              </li>
            </ol>
          </nav>
        </div>

        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
              <div className="row justify-content-end">
                  <div className="col-md-5 col-lg-3">
                    <Link to="inventory-purchase">
                      <button type="button" className="btn btn-primary">
                        <img
                          src="img/fi_plus.png"
                          alt=""
                          className="img-fluid me-2"
                        ></img>
                        {lang.Purchase_New_Item || "Purchase New Item"}
                      </button>
                    </Link>
                  </div>
                </div>

                <div className="row pt-3">
                  <div className="rr col-9">
                    {lang.Inventory_List || "Inventory List"}
                  </div>
                </div>

                <div className="card-body">
                  <div classNames="d-flex mt-3 mb-3"></div>
                  <div classNames="d-flex mt-3 mb-3">
                    <Table
                      lang={lang}
                      data={inventoryList}
                      columns={columns}
                      // returnTable={returnTable}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="modal fade" id="myModal" tabindex="-1">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleQtyUpdate();
                  }}
                >
                  <div className="row mb-2 mt-5 d-flex justify-content-center">
                    <div className="col-sm-8  mb-3">
                      <label htmlFor="" className="w-100">
                        <span style={{ fontSize: "14px" }}>
                          {lang.Item || "Item"}
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          value={invToUpdate.inventory_item}
                          disabled
                        />
                      </label>
                    </div>
                  </div>
                  <div className="row mb-2 d-flex justify-content-center">
                    <div className="col-sm-8  mb-3">
                      <label htmlFor="" className="w-100">
                        <span style={{ fontSize: "14px" }}>
                          {lang.Investor || "Investor"}
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          value={invToUpdate.FullName}
                          disabled
                        />
                      </label>
                    </div>
                  </div>
                  <div className="row mb-2 d-flex justify-content-center">
                    <div className="col-sm-8  mb-3">
                      <label htmlFor="" className="w-100">
                        <span style={{ fontSize: "14px" }}>
                          {lang.Account || "Account"}
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          value={invToUpdate.bank_account}
                          disabled
                        />
                      </label>
                    </div>
                  </div>
                  <div className="row mb-2 d-flex justify-content-center">
                    <div className="col-sm-8  mb-3">
                      <label htmlFor="" className="w-100">
                        <span style={{ fontSize: "14px" }}>
                          {lang.Balance || "Balance"}
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          value={invToUpdate.balance}
                          disabled
                        />
                      </label>
                    </div>
                  </div>
                  <div className="row mb-2 d-flex justify-content-center">
                    <div className="col-sm-8  mb-3">
                      <label htmlFor="" className="w-100">
                        <span style={{ fontSize: "14px" }}>
                          {lang.Price || "Price"}
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={invToUpdate.inventory_price}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="row mb-2 d-flex justify-content-center">
                    <div className="col-sm-8  mb-3">
                      <label htmlFor="" className="w-100">
                        <span style={{ fontSize: "14px" }}>
                          {lang.Quantity || "Quantity"}
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          value={newQty}
                          onChange={(e) => setNewQty(e.target.value)}
                          required
                        />
                      </label>
                    </div>
                  </div>
                  <div className="text-center mb-5">
                    <button
                      type="button"
                      className="btn-save"
                      data-bs-dismiss="modal"
                    >
                      {lang.Cancel || "Cancel"}
                    </button>
                    <button type="submit" className="btn-cancel">
                      {lang.Add_Now || "Add Now"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
