import React, { useEffect, useState } from "react";
import IndeterminateCheckbox from "../components/IndeterminateChechbox";
import Table from "../components/Table";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

export default function Investment({ lang }) {
  const [investorList, setInvestorList] = useState([]);
  const [investorToDelete, setInvestorToDelete] = useState();
  const [branchId, setBranchId] = useState();

  const navigate = useNavigate();

  const returnTable = async (table) => {
    await setInvestorToDelete(table.map((item) => item.original.id));
  };

  const fetchInvestor = () => {
    try {
      axios
        .get("https://api.amancredits.com/investor", {
          params: {
            branch_id: branchId,
          },
        })
        .then((res) => {
          setInvestorList(res.data.result);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const columns = [
    {
      id: "select",
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
    },
    {
      header: lang.Investor_Name || "Investor Name",
      accessorKey: "FullName",
    },
    {
      header: lang.Identity_Number || "Identity Number",
      accessorKey: "ValidNationalID",
    },
    {
      header: lang.Balance || "Balance",
      accessorKey: "balance",
    },
    {
      header: lang.Mobile_Number || "Mobile Number",
      accessorKey: "MobilePhoneNo",
    },
    {
      header: lang.Status || "Status",
      accessorKey: "status",
      cell: (row) => <p>{row.getValue() === 1 ? "Active" : "In-active"}</p>,
    },
    {
      header: lang.Actions || "Actions",
      cell: (row) => (
        <button
          className="btn btn-primary"
          onClick={(e) => {
            e.preventDefault();
            navigate(
              "/dashboard/customer/profile/investor/" + row.row.original.id
            );
          }}
        >
          {lang.View || "View"}
        </button>
      ),
    },
  ];

  useEffect(() => {
    if(branchId){
      fetchInvestor();
    }
  }, [branchId]);
  useEffect(() => {
    setBranchId(
      JSON.parse(JSON.parse(sessionStorage.getItem("aman-admin")).admin).id
    );
  }, [])
  useEffect(() => {
    if (investorToDelete) {
      try {
        axios
          .put("https://api.amancredits.com/investor", {
            idList: investorToDelete,
          })
          .then((res) => {
            console.log("Success");
            fetchInvestor();
          })
          .catch((err) => {
            console.error("error");
          });
      } catch (err) {
        console.error(err);
      }
    }
  }, [investorToDelete]);
  return (
    <div>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>
            {lang.Investment_Loan_Management || "Investment/Loan Management"}
          </h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                >
                  <path d="m21.743 12.331-9-10c-.379-.422-1.107-.422-1.486 0l-9 10a.998.998 0 0 0-.17 1.076c.16.361.518.593.913.593h2v7a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-4h4v4a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-7h2a.998.998 0 0 0 .743-1.669z"></path>
                </svg>
              </li>
              <li className="breadcrumb-item">
                {lang.Investment_Loan_Management ||
                  "Investment/Loan Management"}
              </li>
              <li className="breadcrumb-item active">
                {lang.Investor_List || "Investor List"}
              </li>
            </ol>
          </nav>
        </div>

        <div className="card-body pt-3">
          <ul className="nav nav-tabs nav-tabs-bordered">
            <li className="nav-item">
              <Link to="/dashboard/investor-report">
                <button className="nav-link active ">
                  {lang.Investor_List || "Investor List"}
                </button>
              </Link>
            </li>

            <li className="nav-item">
              <Link to="/dashboard/investment-deposite">
                <button className="nav-link ">
                  {lang.Deposit_Process || "Deposit Process"}
                </button>
              </Link>
            </li>

            <li className="nav-item">
              <Link to="/dashboard/withdraw">
                <button className="nav-link ">
                  {" "}
                  {lang.Withdrawal_Process || "Withdrawal Process"}{" "}
                </button>
              </Link>
            </li>
            {/* 
              <li className="nav-item">
              <Link to ="/bank-transfer">
                <button className="nav-link"> Bank Transfer </button>
     
                </Link>
              </li> */}
          </ul>
        </div>

        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="row justify-content-end">
                    <div className="col-md-4 col-lg-3">
                      <Link to="add-investor">
                        <button
                          type="button"
                          className="btn btn-primary d-flex justify-content-end"
                        >
                          <img
                            src="img/fi_plus.png"
                            alt=""
                            className="img-fluid me-2"
                          />{" "}
                          {lang.Add_New_Investor || "Add New Investor"}
                        </button>
                      </Link>
                    </div>
                  </div>

                  <div className="row pt-3 pb-3">
                    <div className="rr col-9">
                      {lang.Investor_List || "Investor List"}
                    </div>
                  </div>

                  <div classNames="d-flex mt-3 mb-3">
                    <Table
                      lang={lang}
                      data={investorList}
                      columns={columns}
                      returnTable={returnTable}
                      deleteButton={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
