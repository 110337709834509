// // store.js
// import { createStore, applyMiddleware } from 'redux';
// import thunk from 'redux-thunk';
// import rootReducer from './authReducer';

// const store = createStore(rootReducer, applyMiddleware(thunk));

// export default store;

import { configureStore } from '@reduxjs/toolkit'
import authReducer from "./authReducer"

export default configureStore({
  reducer: {
    auth: authReducer
  },
})