import React, { useEffect } from 'react';
import ApexCharts from 'apexcharts';

const ReportsChart = ({lang}) => {
  useEffect(() => {
    new ApexCharts(document.querySelector("#reportsChart"), {
      series: [80],
      chart: {
        height: 315,
        type: 'radialBar',
        offsetY: -20,
        toolbar: {
          show: true
        }
      },
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: '#fff',
          strokeWidth: '67%',
          margin: 0,
          dropShadow: {
            enabled: true,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.35
          }
        }
      },
      stroke: {
        lineCap: 'round'
      },
      grid: {
        padding: {
          top: -10
        }
      },
      fill: {
        colors: ['#0F60FF']
      },
      labels: [`${lang.Average_Results || 'Average Results'}`],
    }).render();
  }, []);

  return <div id="reportsChart" />;
};

export default ReportsChart;
