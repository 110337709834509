import React from 'react'

export default function Zakat() {
  return (
    <>
      <main id="main" className="main">
          <div className="container-fluid">
            <div className="pagetitle">
              <h1>General Management</h1>
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    {" "}
                    <img src="img/homeicon.png" alt="" className="img-fluid"/>
                 </li>
                  <li className="breadcrumb-item">General Management</li>
                  {/* <li className="breadcrumb-item">Dealer List</li> */}
                  <li className="breadcrumb-item active">Zakat</li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="container-fluid">
            <section className="section">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex mt-3 mb-3">
                        <h4 className="headingh4 fw-bold">Zakat</h4>
                      </div>

                      <div className="d-flex mt-3 mb-3 justify-content-end">
                        <button type="submit" className="btn-save">
                          Cancel{" "}
                        </button>
                        <button type="submit" className="btn-cancel">
                          Save & Publish{" "}
                        </button>
                      </div>

                      <form>
                   

                     
                        <div className="row mb-3 d-flex justify-content-center">
                        <div className="col-sm-6">
                            <select className="form-select text-primary" aria-label="Default select example">
                              <option selected="">Select user</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </select>
                          </div>
                      </div>
                      <div className="row mb-3 d-flex justify-content-center">
                        <div className="col-sm-6">
                            <select className="form-select text-primary" aria-label="Default select example">
                              <option selected="">Type Of Zakat</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </select>
                          </div>
                      </div>

                      

                        
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main> 
    </>
  )
}
