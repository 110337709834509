import axios from "axios";
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-multi-date-picker";
import arabic from "react-date-object/calendars/arabic";
import arabic_ar from "react-date-object/locales/arabic_ar";

export default function Addinvestor({ lang }) {
  const [fullName, setFullName] = useState();
  const [releaseDate, setReleaseDate] = useState();
  const [source, setSource] = useState();
  const [idNum, setIdNum] = useState();
  const [DoB, setDoB] = useState();
  const [gender, setGender] = useState();
  const [mobileNum, setMobileNum] = useState();
  const [email, setEmail] = useState();
  const [nationality, setNationality] = useState();
  const country = lang.Saudi_Arabia;
  const [city, setCity] = useState();
  const [states, setStates] = useState([]);
  const [fullAddress, setFullAddress] = useState();
  const [occupation, setOccupation] = useState();
  const [employer, setEmployer] = useState();
  const [designation, setDesignation] = useState();
  const [description, setDescription] = useState();
  const [bankAccount, setBankAccount] = useState();
  const [balance, setBalance] = useState();
  const [branchId, setBranchId] = useState();
  const [nationalities, setNationalities] = useState([]);
  const [selectedCalendar, setSelectedCalendar] = useState("arabic");

  const navigate = useNavigate();

  const handleCalendarChange = (calendarType) => {
    setSelectedCalendar(calendarType);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleSubmit = () => {
    try {
      axios
        .post("https://api.amancredits.com/create-user/investor", {
          full_name: fullName,
          valid_id: idNum,
          phone_no: mobileNum,
          gender: gender,
          DoB: DoB,
          release_date: releaseDate,
          source: source,
          nationality: nationality,
          country: country,
          city: city,
          address: fullAddress,
          email: email,
          password: "12345678",
          occupation: occupation,
          designation: designation,
          employer_name: employer,
          description: description,
          bank_account: bankAccount,
          balance: balance,
          branch_id: branchId,
        })
        .then((res) => {
          if (res.status === 200) {
            setFullName("");
            setIdNum("");
            setMobileNum("");
            setGender("");
            setDoB("");
            setNationality("");
            setCity("");
            setFullAddress("");
            setEmail("");
            setOccupation("");
            setDescription("");
            setDesignation("");
            setEmployer("");
            setBalance("");
            setBankAccount("");
            toast.success(res.data.Message, {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            navigate(-1);
          } else {
            console.log("error occured while uploading");
            toast.error(res.data.Error, {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
      toast.error("Error Occured", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleBlur = () => {
    if (idNum.trim() !== "") {
      axios
        .get("https://api.amancredits.com/get-customer/" + idNum)
        .then((res) => {
          setFullName(res.data.result[0].FullName);
          setMobileNum(res.data.result[0].MobilePhoneNo);
          setGender(res.data.result[0].Gender);
          setReleaseDate(res.data.result[0].ReleaseDate);
          setDoB(res.data.result[0].DoB);
          setNationality(res.data.result[0].Nationality);
          setCity(res.data.result[0].City);
          setFullAddress(res.data.result[0].Address);
          setEmail(res.data.result[0].Email);
          setOccupation(res.data.result[0].Occupation);
          setDescription(res.data.result[0].Description);
          setDesignation(res.data.result[0].Designation);
          setEmployer(res.data.result[0].EmployerName);
          setSource(res.data.result[0].Source);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  useEffect(() => {
    try {
      axios
        .get("https://api.amancredits.com/get-states")
        .then((res) => {
          setStates(res.data.result);
        })
        .catch((err) => {
          console.error(err);
        });
      axios
        .get("https://api.amancredits.com/get-nationalities")
        .then((res) => {
          setNationalities(res.data.result);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }

    setBranchId(
      JSON.parse(JSON.parse(sessionStorage.getItem("aman-admin")).admin).id
    );
  }, []);
  return (
    <>
      <main id="main" class="main">
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <div class="pagetitle">
          <h1>
            {lang.Investment_Loan_Management || "Investment/Loan Management"}
          </h1>
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                >
                  <path d="m21.743 12.331-9-10c-.379-.422-1.107-.422-1.486 0l-9 10a.998.998 0 0 0-.17 1.076c.16.361.518.593.913.593h2v7a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-4h4v4a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-7h2a.998.998 0 0 0 .743-1.669z"></path>
                </svg>
              </li>
              <li class="breadcrumb-item">
                {lang.Investment_Loan_Management ||
                  "Investment/Loan Management"}
              </li>
              <li class="breadcrumb-item">
                {lang.Investment_List || "Investment List"}
              </li>
              <li class="breadcrumb-item active">
                {lang.Add_New_Investor || "Add New Investor"}r
              </li>
            </ol>
          </nav>
        </div>

        <section class="section">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex mt-3 mb-3">
                    <h4 class="headingh4 fw-bold">
                      {lang.Add_New_Investor || "Add New Investor"}
                    </h4>
                  </div>

                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    <div className="d-flex flex-column-reverse flex-lg-row mt-3 mb-3 justify-content-end">
                      <label className="select-calendar mt-3 mt-lg-0">
                        <span style={{ fontSize: "14px" }}>
                          {lang.select_calendar || "Select Calendar"}:
                        </span>
                        <div className="form-check">
                          <input
                            type="radio"
                            className="form-check-input"
                            id="arabicRadio"
                            value="arabic"
                            checked={selectedCalendar === "arabic"}
                            onChange={() => handleCalendarChange("arabic")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="arabicRadio"
                          >
                            {lang.arabic || "Arabic"}
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            type="radio"
                            className="form-check-input"
                            id="gregorianRadio"
                            value="gregorian"
                            checked={selectedCalendar === "gregorian"}
                            onChange={() => handleCalendarChange("gregorian")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="gregorianRadio"
                          >
                            {lang.gregorian || "Gregorian"}
                          </label>
                        </div>
                      </label>
                      <div className="d-flex justify-content-end">
                        <button
                          className="btn-save"
                          type="button"
                          onClick={handleCancel}
                        >
                          {lang.Cancel || "Cancel"}
                        </button>
                        <button type="submit" className="btn-cancel ms-4">
                          {lang.Save_And_Publish || "Save & Publish"}
                        </button>
                      </div>
                    </div>
                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Full_Name || "Full Name"}:
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                            required
                          />
                        </label>
                      </div>
                      <div className="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Valid_Identity_Number ||
                              "Valid Identity Number"}
                            :
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            value={idNum}
                            onChange={(e) => setIdNum(e.target.value)}
                            onBlur={handleBlur}
                            required
                          />
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Release_Date || "Release Date"}:
                          </span>
                          <DatePicker
                            className="form-control w-100"
                            required
                            containerStyle={{
                              width: "100%",
                              height: "100%",
                            }}
                            style={{
                              width: "100%",
                              height: "100%",
                              margin: "0",
                            }}
                            calendar={
                              selectedCalendar === "arabic" ? arabic : ""
                            }
                            locale={
                              selectedCalendar === "arabic" ? arabic_ar : ""
                            }
                            value={releaseDate}
                            onChange={(e) => {
                              const dateObject = new Date(e);
                              setReleaseDate(dateObject);
                            }}
                          />
                        </label>
                      </div>
                      <div className="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Source || "Source"}:
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            value={source}
                            onChange={(e) => setSource(e.target.value)}
                            required
                          />
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-4">
                        <lable className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Gender || "Gender"}:
                          </span>
                          <select
                            name=""
                            id=""
                            className="form-select"
                            onChange={(e) => setGender(e.target.value)}
                            required
                          >
                            <option value="" selected></option>
                            <option value="Male">{lang.Male || "Male"}</option>
                            <option value="Female">
                              {lang.Female || "Female"}
                            </option>
                          </select>
                        </lable>
                      </div>

                      <div className="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Date_of_Birth || "Date of Birth"}:
                          </span>
                          <DatePicker
                            className="form-control w-100"
                            required
                            containerStyle={{
                              width: "100%",
                              height: "100%",
                            }}
                            style={{
                              width: "100%",
                              height: "100%",
                              margin: "0",
                            }}
                            calendar={
                              selectedCalendar === "arabic" ? arabic : ""
                            }
                            locale={
                              selectedCalendar === "arabic" ? arabic_ar : ""
                            }
                            value={DoB}
                            onChange={(e) => {
                              const dateObject = new Date(e);
                              setDoB(dateObject);
                            }}
                          />
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Mobile_Number || "Mobile Number"}:
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            value={mobileNum}
                            onChange={(e) => setMobileNum(e.target.value)}
                            required
                          />
                        </label>
                      </div>

                      <div className="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Email || "Email"}:
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Nationality || "Nationality"}:
                          </span>
                          <select
                            name="nationality"
                            className="form-select"
                            aria-label="Default select example"
                            value={nationality}
                            onChange={(e) => setNationality(e.target.value)}
                            required
                          >
                            <option selected disabled value=""></option>
                            {nationalities.map((nationality, index) => (
                              <option
                                key={index}
                                value={
                                  lang.Lang === "en"
                                    ? nationality.country_enNationality
                                    : nationality.country_arNationality
                                }
                              >
                                {lang.Lang === "en"
                                  ? nationality.country_enNationality
                                  : nationality.country_arNationality}
                              </option>
                            ))}
                          </select>
                        </label>
                      </div>

                      <div className="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Country || "Country"}:
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            value={country}
                            required
                          />
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.State || "State"}:
                          </span>
                          <select
                            name=""
                            id=""
                            className="form-select"
                            onChange={(e) => setCity(e.target.value)}
                            required
                          >
                            <option selected disabled></option>
                            {states.map((state) => (
                              <option key={state.city_id} value={state.city_id}>
                                {lang.Lang === "en"
                                  ? state.name_en
                                  : state.name_ar}
                              </option>
                            ))}
                          </select>
                        </label>
                      </div>

                      <div className="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Full_Address || "Full Address"}:
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            value={fullAddress}
                            onChange={(e) => setFullAddress(e.target.value)}
                            required
                          />
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Occupation || "Occupation"}:
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            value={occupation}
                            onChange={(e) => setOccupation(e.target.value)}
                          />
                        </label>
                      </div>

                      <div className="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Employer_Name || "Employer Name"}:
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            value={employer}
                            onChange={(e) => setEmployer(e.target.value)}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="col-lg-8">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Designation || "Designation"}:
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            value={designation}
                            onChange={(e) => setDesignation(e.target.value)}
                          />
                        </label>
                      </div>
                    </div>
                    <div class="row mb-3 d-flex justify-content-center">
                      <div class="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Bank_Account || "Bank Account"}:
                          </span>
                          <input
                            type="text"
                            class="form-control"
                            onChange={(e) => setBankAccount(e.target.value)}
                            value={bankAccount}
                            required
                          />
                        </label>
                      </div>

                      <div class="col-lg-4">
                        <label htmlFor="" className="w-100">
                          <span style={{ fontSize: "14px" }}>
                            {lang.Opening_Balance || "Opening Balance"}:
                          </span>
                          <input
                            type="text"
                            class="form-control"
                            onChange={(e) => setBalance(e.target.value)}
                            value={balance}
                          />
                        </label>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
